import MessageGroup from '@/models/graphql/MessageGroup';
import GraphQlClients from '@/utils/enums/GraphQlClients';
import ChatBaseRepository from '@/repositories/chat/ChatBaseRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import CreateInterface from '@/repositories/base/CreateInterface';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import { ApolloQueryResult } from '@apollo/client';
import ReadInterface from '@/repositories/base/ReadInterface';
import { print } from 'graphql/language/printer';
import gql from 'graphql-tag';

/* eslint-disable max-len,@typescript-eslint/camelcase,@typescript-eslint/naming-convention */
export default class MessageGroupRepository extends ChatBaseRepository<MessageGroup>
  implements CreateInterface<MessageGroup>, ReadInterface<MessageGroup>, UpdateInterface<MessageGroup> {
  filter(params: GqlPayloadParams): Promise<MessageGroup[]> {
    switch (params.operationName) {
      case 'LastMessageByGroup':
        return buildQueryGql({
          operationName: 'LastMessageByGroup',
          operation: 'messageGroup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupLastMessageByGroupFragment',
          authUser: params.authUser,
        })
          .then((query) => this.use()
            .query<{ messageGroup: MessageGroup[] }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            messageGroup: MessageGroup[];
          }>) => response.data.messageGroup)
          .then((response) => response.map((item) => MessageGroup.hydrate(item as unknown as object)) as unknown as MessageGroup[]);

      default:
        return buildQueryGql({
          operationName: params.operationName || 'LoadMessageGroups',
          operation: 'messageGroup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: params.fragmentName || 'messageGroupFullFragment',
          authUser: params.authUser,
        })
          .then((query) => this.use()
            .query<{ messageGroup: MessageGroup[] }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            messageGroup: MessageGroup[];
          }>) => response.data.messageGroup)
          .then((response) => response.map((item) => MessageGroup.hydrate(item as unknown as object)) as unknown as MessageGroup[]);
    }
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'MessageGroupCount',
      operation: 'messageGroup',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'messageGroupCountFragment',
    })
      .then((query) => this.use()
        .query<{
          messageGroup: CountResponseParams[];
        }, {}>(query, params.definition.variables, false))
      .then((response: ApolloQueryResult<{
        messageGroup: CountResponseParams[];
      }>) => response.data.messageGroup[0]._count); // eslint-disable-line no-underscore-dangle
  }

  setActiveUser(params: GqlPayloadParams): Promise<MessageGroup | null> {
    return buildMutationGql({
      operationName: 'AddActivateUserInMessageGroup',
      operation: 'activateUserInMessageGroup',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'messageGroupBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.use()
        .mutate(mutation, params.definition.variables))
      .then(() => new MessageGroup());
  }

  setInactiveUser(params: GqlPayloadParams): Promise<MessageGroup | null> {
    return buildMutationGql({
      operationName: 'AddInactivateUserInMessageGroup',
      operation: 'inactivateUserInMessageGroup',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'messageGroupBaseFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.use()
        .mutate(mutation, params.definition.variables))
      .then(() => new MessageGroup());
  }

  create(params: GqlPayloadParams): Promise<MessageGroup | null> {
    switch (params.operationName) {
      case 'createConversation':
        return buildMutationGql({
          operationName: 'createConversation',
          operation: 'createConversation',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'AddParticipants':
        return buildMutationGql({
          operationName: 'AddParticipants',
          operation: 'addParticipants',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      default:
        return Promise.resolve(null);
    }
  }

  update(params: GqlPayloadParams): Promise<MessageGroup | null> {
    switch (params.operationName) {
      case 'LeaveConversation':
        return buildMutationGql({
          operationName: 'LeaveConversation',
          operation: 'leaveConversation',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'DeclineConversation':
        return buildMutationGql({
          operationName: 'DeclineConversation',
          operation: 'declineConversation',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'AcceptConversation':
        return buildMutationGql({
          operationName: 'AcceptConversation',
          operation: 'acceptConversation',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'DisconnectConversation':
        return buildMutationGql({
          operationName: 'DisconnectConversation',
          operation: 'disconnectConversation',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'Typing':
        return buildMutationGql({
          operationName: 'Typing',
          operation: 'typing',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'OpenChat':
        return buildMutationGql({
          operationName: 'OpenChat',
          operation: 'openChat',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      case 'CloseChat':
        return buildMutationGql({
          operationName: 'CloseChat',
          operation: 'closeChat',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: '',
        })
          .then((mutation) => this.use(GraphQlClients.CHAT_CLIENT)
            .mutate(mutation, params.definition.variables))
          .then(() => new MessageGroup());

      default:
        return Promise.resolve(null);
    }
  }

  get(params: GqlPayloadParams): Promise<MessageGroup | null> {
    switch (params.operationName) {
      case 'FindMessageGroupForTarget':
        return buildQueryGql({
          operationName: 'FindMessageGroupForTarget',
          operation: 'messageGroup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupForTargetFragment',
          authUser: params.authUser,
        })
          .then((query) => this.use()
            .query<{ messageGroup: MessageGroup[] }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            messageGroup: MessageGroup[];
          }>) => response.data.messageGroup[0] || null);

      case 'MessageGroup':
        return buildQueryGql({
          operationName: 'LoadMessageGroup',
          operation: 'messageGroup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupFullFragment',
          authUser: params.authUser,
        })
          .then((query) => this.use()
            .query<{ messageGroup: MessageGroup[] }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            messageGroup: MessageGroup[];
          }>) => response.data.messageGroup[0] || null)
          .then((response) => MessageGroup.hydrate(response as unknown as object) as unknown as MessageGroup);

      case 'MessageGroupLookup':
        return buildQueryGql({
          operationName: 'MessageGroupLookup',
          operation: 'messageGroupLookup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupBaseFragment',
          authUser: params.authUser,
        })
          .then((query) => {
            let q = print(query);
            const filter = (params.definition as GqlQueryDefinition).filter?.value;
            if (filter) {
              const {
                myUid,
                userUids,
                targetUid,
                groupType_in,
              } = filter as {
                myUid: string;
                userUids: string[];
                targetUid: string;
                groupType_in: string[];
              };
              q = q.replace('($filter: _MessageGroupFilter)', '')
                .replace('filter: $filter',
                  `myUid: "${myUid}"
                 userUids: [${userUids.map((uid) => `"${uid}"`)
    .join(',')}]
                 groupType_in: [${groupType_in.map((type) => `"${type}"`)
    .join(',')}]
                 targetUid: "${targetUid}"`);
            }
            return this.use()
              .query<{
                messageGroupLookup: MessageGroup[];
              }, {}>(gql(q), params.definition.variables, false);
          })
          .then((response: ApolloQueryResult<{
            messageGroupLookup: MessageGroup[];
          }>) => response.data.messageGroupLookup[0])
          .then((response) => MessageGroup.hydrate(response as unknown as object) as unknown as MessageGroup);

      case 'messageGroupLookupWithoutTarget':
        return buildQueryGql({
          operationName: 'LoadMessageGroupLookupWithoutTarget',
          operation: 'messageGroupLookup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupBaseFragment',
          authUser: params.authUser,
        })
          .then((query) => {
            let q = print(query);
            const filter = (params.definition as GqlQueryDefinition).filter?.value;
            if (filter) {
              const {
                myUid,
                userUids,
                groupType_in,
              } = filter as {
                myUid: string;
                userUids: string[];
                groupType_in: string[];
              };
              q = q.replace('($filter: _MessageGroupFilter)', '')
                .replace('filter: $filter',
                  `myUid: "${myUid}",
                 userUids: [${userUids.map((uid) => `"${uid}"`)
    .join(',')}],
                 groupType_in: [${groupType_in.map((groupType) => `"${groupType}"`)
    .join(',')}]`);
            }
            return this.use()
              .query<{
                messageGroupLookup: MessageGroup[];
              }, {}>(gql(q), params.definition.variables, false);
          })
          .then((response: ApolloQueryResult<{
            messageGroupLookup: MessageGroup[];
          }>) => response.data.messageGroupLookup[0])
          .then((response) => MessageGroup.hydrate(response as unknown as object) as unknown as MessageGroup);

      case 'messageGroupLookupWithTarget':
        return buildQueryGql({
          operationName: 'MessageGroupLookupWithTarget',
          operation: 'messageGroup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupBaseFragment',
          alias: 'messageGroupLookup',
          authUser: params.authUser,
        })
          .then((query) => this.use()
            .query<{
              messageGroupLookup: MessageGroup[];
            }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            messageGroupLookup: MessageGroup[];
          }>) => response.data.messageGroupLookup[0])
          .then((response) => MessageGroup.hydrate(response as unknown as object) as unknown as MessageGroup);

      case 'IsTheCompanyGroupExists':
        return buildQueryGql({
          operationName: 'IsTheCompanyGroupExists',
          operation: 'messageGroup',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'messageGroupBaseFragment',
          authUser: params.authUser,
        })
          .then((query) => this.use()
            .query<{
              messageGroup: MessageGroup[];
            }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            messageGroup: MessageGroup[];
          }>) => response.data.messageGroup[0])
          .then((response) => MessageGroup.hydrate(response as unknown as object) as unknown as MessageGroup);
      default:
        return Promise.resolve(null);
    }
  }
}
