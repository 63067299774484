




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import OgLinkCard from '@/components/cards/OgLinkCard.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import StandardModal from '@/components/modals/StandardModal.vue';
import PresenterComponent from '@/components/PresenterComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import ToastActionParams from '@/utils/types/ToastActionParams';
import Exhibitor from '@/models/graphql/Exhibitor';
import { Data } from '@/utils/types/WidgetData';
import Product from '@/models/graphql/Product';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import LargeProduct from '@/models/graphql/LargeProduct';
import Deal from '@/models/graphql/Deal';
import GUUID from '@/utils/GUUID';
import GroupType from '@/utils/enums/chat/GroupType';
import ToastActionType from '@/utils/enums/ToastActionType';
import MessageType from '@/utils/enums/chat/MessageType';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import MessageGroup from '@/models/graphql/MessageGroup';
import Message from '@/models/graphql/Message';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import Community from '@/models/graphql/Community';

const chatStore = namespace('ChatDispatcherStore/ChatStore');
const toastStore = namespace('ToastStore');

/* eslint-disable no-underscore-dangle */
@Component({
  components: {
    PresenterComponent,
    StandardModal,
    ButtonIconComponent,
    TextAreaComponent,
    ButtonComponent,
    FontAwesomeComponent,
    OgLinkCard,
  },
})
export default class InformationRequestModal extends Vue {
  @Prop({ default: null })
  private entityType!: EntityType;

  @Prop({ default: null })
  private data!: Data;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  @chatStore.Action
  private checkIfTheCompanyGroupExist!: (payload: {
    userId: string;
    companyId?: string;
  }) => Promise<string | null>;

  @chatStore.Action
  private createConversation!: (model: {
    users: CommunityUser[];
    message: string | null;
    tempId: string;
    messageTempId: string;
    messageType: MessageType | null;
    targetMessageId: string | null;
    targetId: string | null;
    targetType: string | null;
    creatorUserUid?: string | null;
  }) => Promise<boolean>;

  @chatStore.Action
  private sendMessage!: (model: {
    groupId: string;
    message: string;
    tempId: string;
    type: MessageType | null;
    parentMessageId: string | null;
    targetMessageId: string | null;
  }) => void;

  @chatStore.Mutation
  private setNeedToOpenChatReceivingMessageResponse!: (open: boolean) => void;

  @chatStore.Action
  private updateGroup!: (payload: { uid: string; data: Partial<MessageGroup> }) => void;

  @chatStore.Mutation
  private changeStatus!: (status: MessageBoxActions) => void;

  @chatStore.Mutation
  private toggleChat!: (close?: boolean) => void;

  @chatStore.Mutation
  private setSelectedGroup!: (groupId: string) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private modalId = 'request-information-modal';

  private message = '';

  private get exhibitor(): Exhibitor | null {
    if (this.data
      && this.entityType === EntityType.EXHIBITOR) {
      return Exhibitor.hydrate(this.data);
    }
    if (this.data
      && 'exhibitor' in this.data
      && this.data.exhibitor
    ) {
      return Exhibitor.hydrate(this.data.exhibitor as object);
    }
    return null;
  }

  private get presenterData(): Data[] {
    if (this.exhibitor && this.entityType !== EntityType.EXHIBITOR) {
      return [this.exhibitor as unknown as Data];
    }
    return [];
  }

  private get previewTitle(): string {
    if (this.entityType === EntityType.PRODUCT) {
      return `${this.$t('actions.product')}`;
    }
    if (this.entityType === EntityType.LARGE_PRODUCT) {
      return `${this.$t('actions.large-product')}`;
    }
    if (this.entityType === EntityType.DEAL) {
      return `${this.$t('actions.deal')}`;
    }
    return '';
  }

  private get messagePlaceholder(): string {
    if (this.entityType === EntityType.EXHIBITOR) {
      return `${this.$t('actions.message-modal-input-exhibitor-placeholder')}`;
    }
    if (this.entityType === EntityType.PRODUCT) {
      return `${this.$t('actions.message-modal-input-product-placeholder')}`;
    }
    if (this.entityType === EntityType.LARGE_PRODUCT) {
      return `${this.$t('actions.message-modal-input-large-product-placeholder')}`;
    }
    if (this.entityType === EntityType.DEAL) {
      return `${this.$t('actions.message-modal-input-deal-placeholder')}`;
    }
    return '';
  }

  private get previewData(): object | null {
    if (this.data) {
      switch (this.entityType) {
        case EntityType.EXHIBITOR: {
          const exhibitor = Exhibitor.hydrate(this.data);
          let image = '/img/banner/empty.svg';
          let link = window.location.origin;
          if (exhibitor.logoFileResource) {
            image = FileResourceHelper.getFullPath(exhibitor.logoFileResource, 'w400');
          }
          link += `${this.$router.resolve({
            name: 'company-detail',
            params: {
              companyId: exhibitor.uid,
            },
          }).href}`;
          return {
            link,
            title: exhibitor.name,
            image,
            isLoading: false,
          };
        }
        case EntityType.PRODUCT: {
          const product = Product.hydrate(this.data);
          let image = '/img/banner/empty.svg';
          let link = window.location.origin;
          if (product.images && product.images[0] && product.images[0].fullFileResource) {
            image = FileResourceHelper.getFullPath(product.images[0].fullFileResource, 'w400');
          }
          link += `${this.$router.resolve({
            name: 'product-detail',
            params: {
              productId: product.uid,
            },
          }).href}`;
          return {
            link,
            title: product.name,
            image,
            isLoading: false,
          };
        }
        case EntityType.LARGE_PRODUCT: {
          const largeProduct = LargeProduct.hydrate(this.data);
          let image = '/img/banner/empty.svg';
          let link = window.location.origin;
          if (largeProduct.images && largeProduct.images[0] && largeProduct.images[0].fullFileResource) {
            image = FileResourceHelper.getFullPath(largeProduct.images[0].fullFileResource, 'w400');
          }
          link += `${this.$router.resolve({
            name: 'large-product-detail',
            params: {
              largeProductId: largeProduct.uid,
            },
          }).href}`;
          return {
            link,
            title: largeProduct.name,
            image,
            isLoading: false,
          };
        }
        case EntityType.DEAL: {
          const deal = Deal.hydrate(this.data);
          let image = '/img/banner/empty.svg';
          let link = window.location.origin;
          if (deal.displayFileResource) {
            image = FileResourceHelper.getFullPath(deal.displayFileResource, 'w400');
          }
          link += `${this.$router.resolve({
            name: 'deal-detail',
            params: {
              dealId: deal.uid,
            },
          }).href}`;
          return {
            link,
            title: deal.title,
            image,
            isLoading: false,
          };
        }
        default:
          return null;
      }
    }
    return null;
  }

  private mounted(): void {
    this.$bvModal.show(this.modalId);
  }

  private sendInformationRequest(): void {
    if (this.message.trim().length > 0 && this.exhibitor) {
      this.$bvModal.hide(this.modalId);
      const tempId = GUUID.uuidv4();
      const messageTempId = GUUID.uuidv4();
      let url = `\n ${window.location.origin}`;
      if (this.entityType === EntityType.EXHIBITOR) {
        url += `${this.$router.resolve({
          name: 'company-detail',
          params: {
            companyId: this.data.uid as string,
          },
        }).href}`;
      }
      if (this.entityType === EntityType.PRODUCT) {
        url += `${this.$router.resolve({
          name: 'product-detail',
          params: {
            productId: this.data.uid as string,
          },
        }).href}`;
      }
      if (this.entityType === EntityType.LARGE_PRODUCT) {
        url += `${this.$router.resolve({
          name: 'large-product-detail',
          params: {
            largeProductId: this.data.uid as string,
          },
        }).href}`;
      }
      if (this.entityType === EntityType.DEAL) {
        url += `${this.$router.resolve({
          name: 'deal-detail',
          params: {
            dealId: this.data.uid as string,
          },
        }).href}`;
      }
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        this.entityType,
        StatLoggerActions.CLICK,
        'requestInformation',
        -1,
        this.data.uid as string,
        StatLoggerCategories.INFO,
        this.$i18n.locale,
      );
      this.checkIfTheCompanyGroupExist({
        userId: this.authUser.uid,
        companyId: this.exhibitor.uid as string,
      })
        .then((groupUid) => {
          if (groupUid) {
            this.sendMessage({
              tempId: messageTempId,
              message: `${this.message}${url}`,
              groupId: groupUid,
              parentMessageId: null,
              targetMessageId: this.data.uid as string,
              type: MessageType.LINK,
            });
            this.setNeedToOpenChatReceivingMessageResponse(true);
          } else if (this.exhibitor
            && this.exhibitor._chatReps
            && this.exhibitor._chatReps.length > 0
          ) {
            const users = [
              ...this.exhibitor._chatReps
                .filter((c) => c.user?.uid !== this.authUser.uid)
                .map((c) => CommunityUser.hydrate(c.user as CommunityUser)),
              CommunityUser.hydrate(this.authUser),
            ];
            this.updateGroup({
              uid: tempId,
              data: {
                uid: tempId,
                tempId,
                users,
                groupType: GroupType.GROUP,
                target: { __typename: EntityType.EXHIBITOR, ...this.exhibitor },
                messages: [Message.hydrate({
                  uid: messageTempId,
                  tempId: messageTempId,
                  content: `${this.message}${url}`,
                  type: MessageType.LINK,
                  user: CommunityUser.hydrate(this.authUser),
                  target: { __typename: this.entityType, ...this.data },
                })],
              },
            });
            this.setSelectedGroup(tempId);
            this.changeStatus(MessageBoxActions.MESSAGES);
            this.toggleChat(false);
            this.createConversation({
              message: `${this.message}${url}`,
              targetType: EntityType.EXHIBITOR,
              targetId: this.exhibitor?.uid as string,
              targetMessageId: this.data.uid as string,
              messageType: MessageType.LINK,
              tempId,
              messageTempId,
              users,
              creatorUserUid: this.authUser.uid,
            });
          }
          this.addNewAction({ type: ToastActionType.SENT_MESSAGE });
        })
        .then(() => {
          this.message = '';
        });
    }
  }

  private cancelInformationRequest(): void {
    this.$bvModal.hide(this.modalId);
    this.message = '';
  }
}
