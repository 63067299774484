

































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MessageBoxStatus from '@/utils/enums/chat/MessageBoxActions';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import MessageBoxListView from '@/views/chat/MessageBoxListView.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import ToolboxMessageContainer from '@/components/toolbox/message/ToolboxMessageContainer.vue';
import MessageBoxNewConversation from '@/views/chat/MessageBoxNewConversation.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const chatStore = namespace('ChatDispatcherStore/ChatStore');

@Component({
  components: {
    MessageBoxNewConversation,
    ToolboxMessageContainer,
    MessageBoxListView,
    MessageBoxMessages,
  },
})
export default class MessageBoxContent extends BreakpointWrapper {
  @Prop({
    required: false,
    default: () => [],
  })
  private chatReps!: string[];

  @chatStore.State
  private status!: MessageBoxStatus;

  @chatStore.State
  private selectedUsersForNewGroup!: CommunityUser[];

  @chatStore.State
  private tempTargetId!: string | undefined;

  @chatStore.State
  private tempTargetType!: string | undefined;

  @chatStore.State
  private tempTargetData!: object | null;

  @chatStore.Getter
  private selectedGroup!: MessageGroup | undefined;

  @chatStore.Mutation
  private changeStatus!: (status: MessageBoxStatus) => void;

  @chatStore.Mutation
  private setSelectedGroup!: (group: string | null) => void;

  private readonly MessageBoxStatus = MessageBoxStatus;

  private renderMessageBox = 1;

  private openMessages(group: MessageGroup | null): void {
    this.setSelectedGroup(group?.uid as string);
    this.changeStatus(MessageBoxStatus.MESSAGES);
  }

  @Watch('selectedGroup.uid')
  private reloadMessageBox(): void {
    this.renderMessageBox += 1;
  }
}
