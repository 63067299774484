import RestRepository from '@/repositories/base/RestRepository';
import CommunityUser from '@/models/graphql/CommunityUser';
import AuthResponseParams from '@/utils/types/AuthResponseParams';

export default class AccountManagementRepository extends RestRepository {
  constructor() {
    super(
      `${process.env.VUE_APP_ACCOUNT_MANAGEMENT_BASE_URL}`,
      { 'Content-type': 'application/x-www-form-urlencoded' },
    );
  }

  triggerWelcome(communityCode: string, userId: string): Promise<void> {
    const formData = new URLSearchParams();
    formData.append('community', communityCode);
    formData.append('userUid', userId);

    return this.get({
      path: 'account/trigger-welcome',
      params: formData,
      headers: {},
    });
  }

  triggerRecovery(communityCode: string, email: string): Promise<void> {
    const formData = new URLSearchParams();
    formData.append('community', communityCode);
    formData.append('email', email);

    return this.get({
      path: 'account/trigger-recovery',
      params: formData,
      headers: {},
    });
  }

  checkActivatability(email: string | null, password: string | null, token: string): Promise<object> {
    const formData = new URLSearchParams();
    formData.append('email', email || '');
    formData.append('password', password || '');
    formData.append('token', token);

    return this.post<object>({
      path: 'account/check-activatability',
      data: formData.toString(),
    });
  }

  activateAccount(token: string): Promise<object> {
    const formData = new URLSearchParams();
    formData.append('token', token);

    return this.post<object>({
      path: 'account/activate',
      data: formData.toString(),
    });
  }

  verifyToken(token: string): Promise<boolean> {
    const formData = new URLSearchParams();
    formData.append('token', token);

    return this.get<boolean>({
      path: 'account/verify-token',
      params: formData,
      headers: {},
    });
  }

  register(newUser: Partial<CommunityUser>, communityCode: string): Promise<object> {
    const formData = new URLSearchParams();
    formData.append('email', newUser.email || '');
    formData.append('password', newUser.password || '');
    formData.append('communityCode', communityCode);
    formData.append('firstName', newUser.firstName || '');
    formData.append('lastName', newUser.lastName || '');
    formData.append('jobTitle', newUser.jobTitle || '');
    formData.append('employerName', newUser.employerName || '');

    return this.post<object>({
      path: 'dev/register',
      data: formData.toString(),
    });
  }

  login(username: string, password: string, communityCode: string): Promise<AuthResponseParams> {
    const formData = new URLSearchParams();
    formData.append('email', username);
    formData.append('password', password || '');
    formData.append('communityCode', communityCode);

    return this.post<AuthResponseParams>({
      path: 'dev/login',
      data: formData.toString(),
    });
  }

  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  async logout(token: string): Promise<boolean> {
    return true;
  }
}
