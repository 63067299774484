import { IVueI18n } from 'vue-i18n';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import LocaleModel from '@/models/LocaleModel';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import UiPage from '@/models/graphql/UiPage';
import UiMenu from '@/models/graphql/UiMenu';
import UiOnboarding from '@/models/graphql/UiOnboarding';
import { TabType } from '@/utils/types/TabType';

export default class RootState {
  authUser: CommunityUser | null = null;

  community: Community | null = null;

  pages: UiPage[] = [];

  menus: UiMenu[] = [];

  onBoardings: UiOnboarding[] = [];

  features: CommunityFeature[] = [];

  locales: LocaleModel[] = [];

  i18n: IVueI18n | null = null;

  defaultLocale = 'en_US';

  fallbackLocale = 'en_US';

  loadingLocales = false;

  loadingGuestToken = false;

  dateLocale = {} as Locale;

  currentPage: UiPage | null = null;

  communityTzName = '';

  communityTzAbbreviation = '';

  localTzName = '';

  localTzAbbreviation = '';

  selectedTzName = '';

  selectedTzAbbreviation = '';

  pageClasses = '';

  portalType = '';

  unifyDomain = 'http://localhost:8080';

  exhibitorPortalDomain = 'http://portal.local:9090';

  sessionSelectedTab: TabType = null;

  savedPositions: Map<string, { x: number; y: number }> = new Map();

  exhibitorPortalRootPage: UiPage | null = null;
}
