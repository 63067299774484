











































































































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import PickerCalendarType from '@/utils/enums/PickerCalendarType';
import SliderArrowComponent from '@/components/SliderArrowComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addDays,
  addMonths,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  fromUnixTime,
  getDay,
  getMonth,
  getUnixTime,
  getYear,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { State } from 'vuex-class';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
    SliderArrowComponent,
    ButtonIconComponent,
  },
})
/* eslint-disable no-unused-expressions */
export default class PickerCalendarComponent extends Vue {
  @Prop({
    required: false,
    default: () => [],
  })
  private readonly selectedDates!: string[];

  @Prop({
    required: false,
    default: getUnixTime(startOfMonth(DateTimeHelper.getCurrentDateTime())),
  })
  private readonly selectedMonth!: number;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly events!: string[];

  @Prop({
    required: false,
    default: PickerCalendarType.MULTI,
  })
  private readonly type!: PickerCalendarType;

  @Prop({
    required: false,
    default: false,
  })
  private readonly isDouble!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly showBackToTodayButton!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly showOnlyFutureDate!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly agendaMobileHeader!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly timezone!: string;

  private format = format;

  private getUnixTime = getUnixTime;

  private getDay = getDay;

  private localSelectedDates: string[] = [];

  private rangeStartDate: string | null = null;

  private rangeEndDate: string | null = null;

  private touchendX!: number;

  private touchstartX!: number;

  private toggleCalendar = false;

  private todayDt = startOfDay(DateTimeHelper.getCurrentDateTime());

  private currentDt: Date | undefined = fromUnixTime(this.selectedMonth);

  private currentDtTimestamp = this.currentDt && getUnixTime(startOfDay(this.currentDt));

  private nextDt: Date = addMonths(DateTimeHelper.getCurrentDateTime(), 1);

  private currentMonthDates: Date[][] = [];

  private nextMonthDates: Date[][] = [];

  @State
  private dateLocale!: Locale;

  private get weekDays(): string[] {
    const now = new Date();
    const weekDays: string[] = [];
    const start = startOfWeek(now);
    const end = endOfWeek(now);
    eachDayOfInterval({
      start,
      end,
    })
      .forEach((day) => {
        weekDays.push(format(day, 'EEEEE', { locale: this.dateLocale }));
      });
    return weekDays;
  }

  private get dateHeader(): { myf: string; fmyf: string } | null {
    return {
      myf: this.currentDt
        ? format(this.currentDt, this.$t('app.date.monthYearShort') as string, { locale: this.dateLocale })
        : '',
      fmyf: this.currentDt
        ? format(this.currentDt, this.$t('app.date.monthYearFull') as string, { locale: this.dateLocale })
        : '',
    };
  }

  private get nextDateHeader(): { myf: string } | null {
    return {
      myf: this.nextDt
        ? format(this.nextDt, this.$t('app.date.monthYearShort') as string, { locale: this.dateLocale })
        : '',
    };
  }

  @Watch('toggleCalendar')
  private toggleFilterCalendar(): void {
    this.$emit('toggle-calendar');
  }

  private onMonthNavigation(next: boolean): void {
    if (this.isDouble) {
      let date;
      if (next) {
        date = this.nextDt && addMonths(this.nextDt, 1);
      } else {
        date = this.nextDt && subMonths(this.nextDt, 1);
      }
      this.nextDt = date;
    }

    let nextDate;
    if (next) {
      nextDate = this.currentDt && addMonths(this.currentDt, 1);
    } else {
      nextDate = this.currentDt && subMonths(this.currentDt, 1);
    }
    this.currentDt = nextDate;
    this.fetchCurrentMonthDates();
    this.fetchNextMonthDates();
    this.$nextTick(() => {
      this.loadSelectedDates();
      this.loadEventsDates();
    });
    this.$emit('selected-month-range', {
      start: this.currentDt && getUnixTime(startOfMonth(this.currentDt)),
      end: this.currentDt && getUnixTime(endOfMonth(this.currentDt)),
    });
  }

  private onBackToToday(): void {
    this.currentDt = fromUnixTime(getUnixTime(startOfMonth(DateTimeHelper.getCurrentDateTime())));
    this.fetchCurrentMonthDates();
    this.fetchNextMonthDates();
    this.$nextTick(() => {
      this.loadSelectedDates();
      this.loadEventsDates();
    });
    this.$emit('selected-month-range', {
      start: this.currentDt && getUnixTime(startOfMonth(this.currentDt)),
      end: this.currentDt && getUnixTime(endOfMonth(this.currentDt)),
    });
    this.$emit('back-to-today');
  }

  private handleGesture(): void {
    if (this.touchstartX !== this.touchendX) {
      if (this.touchendX < this.touchstartX + 100) {
        this.onMonthNavigation(true);
      }

      if (this.touchendX - 100 > this.touchstartX) {
        this.onMonthNavigation(false);
      }
    }
  }

  private onSelect(selectedRef: string): void {
    if (this.$refs[selectedRef]) {
      if (PickerCalendarType.SINGLE === this.type) {
        this.resetStyle();
        const elem = (this.$refs[selectedRef] as Element[])[0];
        if (elem) {
          elem.classList.toggle('default');
          elem.classList.toggle('selected');
        }
        const index = this.localSelectedDates.findIndex((d) => d === selectedRef);
        if (index >= 0) {
          this.localSelectedDates = [AgendaStoreHelper.formatDictionaryKey(new Date())];
        } else {
          this.localSelectedDates = [selectedRef];
        }
        this.$emit('on-select', this.localSelectedDates);
      }

      if (PickerCalendarType.RANGE === this.type) {
        if (this.rangeEndDate && this.rangeStartDate) {
          this.rangeEndDate = null;
          this.rangeStartDate = null;
          this.localSelectedDates = [];
          this.resetStyle();
        }
        if (this.rangeStartDate) {
          this.rangeEndDate = selectedRef;
          this.inRange();
        } else {
          this.rangeStartDate = selectedRef;
        }
        const elem = (this.$refs[selectedRef] as Element[])[0];
        if (elem) {
          elem.classList.remove('default');
          elem.classList.add('selected');
        }
      }

      if (PickerCalendarType.MULTI === this.type) {
        const elem = (this.$refs[selectedRef] as Element[])[0];
        if (elem) {
          elem.classList.toggle('default');
          elem.classList.toggle('selected');
        }
        const index = this.localSelectedDates.findIndex((d) => d === selectedRef);
        if (index >= 0) {
          this.localSelectedDates.splice(index, 1);
        } else {
          this.localSelectedDates.push(selectedRef);
        }
        this.$emit('on-select', this.localSelectedDates);
      }
    }
  }

  private inRange(): void {
    if (this.rangeStartDate && this.rangeEndDate && this.type === PickerCalendarType.RANGE) {
      this.resetStyle();
      this.localSelectedDates = this.datesBetweenTwoDates(this.rangeStartDate, this.rangeEndDate);
      this.loadSelectedDates();
      this.$emit('on-select', this.localSelectedDates);
    } else {
      this.resetStyle();
    }
  }

  private resetStyle = (): void => {
    const elements = document.querySelectorAll('.pc-week-date .style');
    elements.forEach((el) => {
      el.classList.remove('selected');
      el.classList.remove('in-range');
      el.classList.add('default');
      el.parentElement?.classList.remove('in-range');
      el.parentElement?.classList.remove('first');
      el.parentElement?.classList.remove('last');
    });
  };

  private datesBetweenTwoDates = (start: string, end: string): string[] => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const result: string[] = [];
    if (start < end) {
      result.push(start);
      while (isBefore(startDate, endDate)) {
        result.push(format(addDays(startDate, 1), 'yyyy-MM-dd'));
      }
    } else if (end < start) {
      result.push(end);
      while (isBefore(endDate, startDate)) {
        result.push(format(addDays(endDate, 1), 'yyyy-MM-dd'));
      }
    } else {
      result.push(start);
    }
    return result;
  };

  private loadSelectedDates(): void {
    if (this.localSelectedDates.length > 0) {
      const date = this.localSelectedDates[0].split('-');
      const year = parseInt(date[0], 10);
      const month = parseInt(date[1], 10);
      if (
        PickerCalendarType.SINGLE === this.type
        && this.$refs[this.localSelectedDates[0]]
        && this.currentDt
        && month === (getMonth(this.currentDt) + 1)
        && year === getYear(this.currentDt)
      ) {
        const elem = (this.$refs[this.localSelectedDates[0]] as Element[])[0];
        if (elem) {
          elem.classList.remove('default');
          elem.classList.add('selected');
        }
      } else if (PickerCalendarType.RANGE === this.type) {
        if (
          this.localSelectedDates.length === 1
          && this.$refs[this.localSelectedDates[0]]
          && this.currentDt && this.nextDt
          && ((month === (getMonth(this.currentDt) + 1)
              && year === getYear(this.currentDt))
            || (month === (getMonth(this.nextDt) + 1)
              && year === getYear(this.nextDt)))
        ) {
          const elem = (this.$refs[this.localSelectedDates[0]] as Element[])[0];
          if (elem) {
            elem.classList.remove('default');
            elem.classList.add('selected');
          }
        } else {
          this.localSelectedDates.forEach((val, index) => {
            const valDate = val.split('-');
            const valYear = parseInt(valDate[0], 10);
            const valMonth = parseInt(valDate[1], 10);
            if (
              this.$refs[val]
              && this.currentDt
              && this.nextDt
              && ((valMonth === (getMonth(this.currentDt) + 1)
                  && valYear === getYear(this.currentDt))
                || (valMonth === (getMonth(this.nextDt) + 1)
                  && valYear === getYear(this.nextDt)))
            ) {
              const elem = (this.$refs[val] as Element[])[0];
              if (elem) {
                if (index === 0 || index === this.localSelectedDates.length - 1) {
                  elem.classList.remove('default');
                  elem.classList.add('selected');
                  elem.parentElement?.classList.add('in-range');
                  index === 0
                    ? elem.parentElement?.classList.add('first')
                    : elem.parentElement?.classList.add('last');
                } else {
                  elem.classList.remove('default');
                  elem.classList.add('in-range');
                  elem.parentElement?.classList.add('in-range');
                }
              }
            }
          });
        }
      } else {
        this.localSelectedDates.forEach((val) => {
          const valDate = val.split('-');
          const valYear = parseInt(valDate[0], 10);
          const valMonth = parseInt(valDate[1], 10);
          if (
            this.$refs[val]
            && this.currentDt
            && this.nextDt
            && ((valMonth === (getMonth(this.currentDt) + 1)
                && valYear === getYear(this.currentDt))
              || (valMonth === (getMonth(this.nextDt) + 1)
                && valYear === getYear(this.nextDt)))
          ) {
            const elem = (this.$refs[val] as Element[])[0];
            if (elem) {
              elem.classList.remove('default');
              elem.classList.add('selected');
            }
          }
        });
      }
    }
  }

  @Watch('events')
  private loadEventsDates(): void {
    if (this.events.length > 0) {
      Object.keys(this.$refs)
        .forEach((key: string) => {
          const elements = this.$refs[key] as Element[];
          if (elements && elements.length > 0) {
            const child = elements[0].querySelector('.event-pill');
            if (child) {
              child.classList.remove('show');
            }
          }
        });

      this.events.forEach((val) => {
        const elements = this.$refs[val] as Element[];
        if (elements && elements.length > 0) {
          const child = elements[0].querySelector('.event-pill');
          if (child) {
            child.classList.add('show');
          }
        }
      });
    }
  }

  private fetchCurrentMonthDates(): void {
    const year = this.currentDt ? getYear(this.currentDt) : 2021;
    const month = (this.currentDt ? getMonth(this.currentDt) : 0) + 1;
    let startDate = new Date(year, month - 1);
    const endDate = endOfMonth(startDate);

    const weeks = [];
    let perWeek = [];
    const difference = differenceInDays(endDate, startDate);

    let index = 0;
    let lastWeek = false;
    perWeek.push(startDate);
    while (differenceInDays(startDate, endDate) < 0) {
      startDate = addDays(startDate, 1);
      if (getDay(startDate) !== 0) {
        perWeek.push(startDate);
      } else if (getMonth(addDays(startDate, 7)) === month - 1) {
        weeks.push(perWeek);
        perWeek = [];
        perWeek.push(startDate);
      } else if (Math.abs(index - difference) > 0) {
        if (!lastWeek) {
          weeks.push(perWeek);
          perWeek = [];
        }
        lastWeek = true;
        perWeek.push(startDate);
      }
      index += 1;
      if (
        (lastWeek && Math.abs(index - difference) === 0)
        || (Math.abs(index - difference) === 0 && perWeek.length === 1)
      ) {
        weeks.push(perWeek);
      }
    }
    this.currentMonthDates = weeks;
  }

  private fetchNextMonthDates(): void {
    const year = this.nextDt ? getYear(this.nextDt) : 2021;
    const month = (this.nextDt ? getMonth(this.nextDt) : 0) + 1;
    let startDate = new Date(year, month - 1);
    const endDate = endOfMonth(startDate);

    const weeks = [];
    let perWeek = [];
    const difference = differenceInDays(endDate, startDate);

    let index = 0;
    let lastWeek = false;
    perWeek.push(startDate);
    while (differenceInDays(startDate, endDate) < 0) {
      startDate = addDays(startDate, 1);
      if (getDay(startDate) !== 0) {
        perWeek.push(startDate);
      } else if (getMonth(addDays(startDate, 7)) === month - 1) {
        weeks.push(perWeek);
        perWeek = [];
        perWeek.push(startDate);
      } else if (Math.abs(index - difference) > 0) {
        if (!lastWeek) {
          weeks.push(perWeek);
          perWeek = [];
        }
        lastWeek = true;
        perWeek.push(startDate);
      }
      index += 1;
      if (
        (lastWeek && Math.abs(index - difference) === 0)
        || (Math.abs(index - difference) === 0 && perWeek.length === 1)
      ) {
        weeks.push(perWeek);
      }
    }
    this.nextMonthDates = weeks;
  }

  private created(): void {
    this.fetchCurrentMonthDates();
    this.fetchNextMonthDates();
  }

  @Watch('selectedDates', { deep: true })
  private updateSelectedDates(): void {
    this.resetStyle();
    this.localSelectedDates = this.selectedDates;
    this.loadSelectedDates();
  }

  private mounted(): void {
    this.$nextTick(() => {
      this.updateSelectedDates();
      this.loadSelectedDates();
      this.loadEventsDates();
    });
  }
}
