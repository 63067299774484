enum ToastActionType {
  BOOKMARK = 'bookmark',
  UNBOOKMARK = 'unbookmark',
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
  SAVE_CHANGES = 'save-changes',
  RESET_FILTERS = 'reset-filters',
  UPDATE_STATUS = 'update-status',
  UPDATE_AVATAR = 'update-avatar',
  REMOVE_AVATAR = 'remove-avatar',
  UPDATE_AVATAR_ERROR = 'update-avatar-error',
  UPDATE_COVER_IMAGE = 'update-cover-image',
  REMOVE_COVER_IMAGE = 'remove-cover-image',
  UPDATE_COVER_IMAGE_ERROR = 'update-cover-image-error',
  SEND_VERIFICATION_EMAIL = 'send-verification-email',
  ACCEPT_CONNECTION_REQUEST = 'accept-connection-request',
  DECLINE_CONNECTION_REQUEST = 'decline-connection-request',
  SEND_CONNECTION_REQUEST = 'send-connection-request',
  REMOVE_CONNECTION_REQUEST = 'remove-connection-request',
  CANCEL_PENDING_REQUEST = 'cancel-pending-request',
  BLOCK_USER = 'block-user',
  UNBLOCK_USER = 'unblock-user',
  DELETE_MESSAGE = 'delete-message',
  DELETE_MEETING = 'delete-meeting',
  MEETING_REQUEST_SENT = 'meeting-request-sent',
  DELETE_NOTE = 'delete-note',
  UPDATE_URL = 'update-url',
  UPDATE_LOGO = 'update-logo',
  REMOVE_LOGO = 'remove-logo',
  UPDATE_LOGO_ERROR = 'update-logo-error',
  UPDATE_BANNER = 'update-banner',
  REMOVE_BANNER = 'remove-banner',
  UPDATE_BANNER_ERROR = 'update-banner-error',
  UPDATE_PERSONAL_INFORMATION = 'update-personal-information',
  UPDATE_COMPANY_INFORMATION = 'update-company-information',
  UPDATE_SPEAKER_INFORMATION = 'update-speaker-information',
  CHAT_ACTION_ERROR = 'chat-action-error',
  DELETE_PRODUCT = 'delete-product',
  UPDATE_PRODUCT = 'update-product',
  CREATE_PRODUCT = 'create-product',
  PUBLISH_PRODUCT = 'publish-product',
  UNPUBLISH_PRODUCT = 'unpublish-product',
  DELETE_DEAL = 'delete-deal',
  UPDATE_DEAL = 'update-deal',
  CREATE_DEAL = 'create-deal',
  DELETE_HANDOUT = 'delete-handout',
  UPDATE_HANDOUT = 'update-handout',
  CREATE_HANDOUT = 'create-handout',
  PUBLISH_DEAL = 'publish-deal',
  SETTINGS_CHANGE_EMAIL = 'setting-change-email',
  SETTINGS_CHANGE_PASSWORD = 'setting-change-password',
  UNPUBLISH_DEAL = 'unpublish-deal',
  UPDATE_COMPANY_USER_ROLE = 'update-company-user-role',
  DELETE_COMPANY_USER_ROLE = 'delete-company-user-role',
  USER_INVITED = 'user-invited',
  DELETE_SELECTION = 'delete-selection',
  CLIPBOARD_COPIED = 'clipboard-copied',
  USER_ACCEPT_INVITATION = 'user-accept-invitation',
  USER_DECLINE_INVITATION = 'user-decline-invitation',
  USER_ACCEPT_INVITATION_ERROR = 'user-accept-invitation-error',
  USER_DECLINE_INVITATION_ERROR = 'user-decline-invitation-error',
  CONVERSATION_MUTED = 'conversation-muted',
  CONVERSATION_UNMUTE = 'conversation-unmute',
  COMMENT_DELETE_SUCCESS = 'comment-delete-success',
  COMMENT_DELETE_FAIL = 'comment-delete-fail',
  UNBLOCK_USERS = 'unblock-users',
  LEFT_COMPANY = 'left-company',
  COPY_TO_CLIPBOARD = 'copy-to-clipboard',
  COPY_SESSION_ID_TO_CLIPBOARD = 'copy-session-id-to-clipboard',
  FAILED_COPY_TO_CLIPBOARD = 'failed-copy-to-clipboard',
  UPDATE_SESSION = 'update-session',
  TAG_RENAMED = 'tag-renamed',
  TAG_REMOVED = 'tag-removed',
  TAG_DELETED = 'tag-deleted',
  TAGS_UPDATED = 'tags-updated',
  SHARE_CONTACT = 'share-contact',
  FEEDBACK_SENT = 'feedback-sent',
  REPOST = 'repost',
  REPOST_TOPIC = 'repost-topic',
  EMAIL_SENT = 'email-sent',
  FAILED_TO_EXPORT_AGENDA = 'failed-to-export-agenda',
  SENT_MESSAGE = 'sent-message',
  DELETE_EXHIBITOR_VIDEO = 'delete-exhibitor-video',
  UPDATE_EXHIBITOR_VIDEO = 'update-exhibitor-video',
  CREATE_EXHIBITOR_VIDEO = 'create-exhibitor-video',
  CREATE_EXHIBITOR_VIDEO_ERROR = 'create-exhibitor-video-error',
}

export default ToastActionType;
