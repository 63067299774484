

































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import AuthStep from '@/utils/enums/AuthStep';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const authenticationStore = namespace('AuthenticationStore');

@Component
export default class AuthPage extends Vue {
  @Getter
  private readonly featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  private readonly authUser!: CommunityUser;

  @Getter
  private readonly community!: Community;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  @authenticationStore.State
  private readonly authStep!: AuthStep | null;

  private readonly AuthStep = AuthStep;

  private get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper
        .getImagePathWithSize(FileResourceHelper.getFullPath(this.community?.logoFileResource), 'w96');
    }
    return '';
  }

  private get contactSupportHref(): string {
    return `mailto:${this.community?.organizer?.email}`;
  }

  private get isSignupEnabled(): boolean {
    return !this.isUnifyExhibitorPortal
      && this.featureByKey(FeatureKeys.USER_SIGNUP)
      && this.featureByKey(FeatureKeys.USER_SIGNUP).enabled;
  }

  private onSignUpClick(): void {
    this.$router.push({ name: 'sign-up' });
  }

  private onSignInClick(): void {
    this.$router.push({ name: 'sign-in' });
  }
}
