






























































import { Component } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Community from '@/models/graphql/Community';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';

@Component({
  components: {
    ButtonComponent,
    ButtonIconComponent,
    FontAwesomeComponent,
    AvatarSoloWidget,
  },
  data(): object {
    return {
      show: false,
    };
  },
})
export default class SignInPromptModal extends BreakpointWrapper {
  @Getter
  protected community!: Community;

  @Getter
  private isLoading!: boolean;

  private FileResourceHelper = FileResourceHelper;

  protected get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w56') !== ''
        ? FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w56')
        : '';
    }
    return this.community?.logoFileResource?.path || '';
  }

  private get modalSize(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.isMobile
      ? 'sm'
      : this.isTablet
        ? 'md'
        : 'lg';
  }

  private onSignInClick(): void {
    this.$bvModal.hide('sign-in-action-modal');
    this.$router.push({ name: 'sign-in' });
  }
}
