















































































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import MessageBoxConversationSummary from '@/utils/types/chat/MessageBoxConversationSummary';
import SoloDoubleAvatarComponent from '@/components/chat/SoloDoubleAvatarComponent.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import MessageBoxMenuComponent from '@/components/chat/MessageBoxMenuComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import MessageMenuItem from '@/utils/enums/chat/MessageMenuItem';
import GroupType from '@/utils/enums/chat/GroupType';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import Session from '@/models/graphql/Session';
import EntityType from '@/utils/enums/EntityType';
import CommunityUser from '@/models/graphql/CommunityUser';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { addHours, getUnixTime } from 'date-fns';
import Meeting from '@/models/graphql/Meeting';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import Event from '@/utils/types/Event';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import { runMathJax } from '@/utils/helpers/LatexHelper';
import { BasicTypes } from '@/utils/types/BasicTypes';
import Exhibitor from '@/models/graphql/Exhibitor';

const agendaStore = namespace('AgendaStore');

@Component({
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    MessageBoxMenuComponent,
    ConfirmModal,
    SoloDoubleAvatarComponent,
    ButtonIconComponent,
  },
})
export default class MessageBoxHeader extends BreakpointWrapper {
  @Getter
  private authUser!: CommunityUser;

  @State
  private selectedTzName!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  private readonly isUnifyExhibitorPortal!: boolean;

  @Prop({
    required: false,
    default: 'ChatStore',
  })
  private readonly storeName!: string;

  @Prop({
    required: false,
    default: -1,
  })
  private forceStatus!: MessageBoxActions;

  @Prop({
    required: false,
    default: null,
  })
  private forceTitle!: string;

  @Prop({
    required: false,
    default: false,
  })
  private forceBackArrow!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private forceConversationListTitle!: string;

  @Prop({
    required: false,
    default: false,
  })
  private disableAction!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private hideCloseButton!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disableGroupCreation!: boolean;

  @Prop({
    required: false,
    default: () => [],
  })
  private chatReps!: string[];

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  private readonly MessageBoxActions = MessageBoxActions;

  private readonly EntityType = EntityType;

  private get isClosed(): boolean {
    return this.getter<boolean>('isClosed');
  }

  private get chatErrors(): ChatErrorList | null {
    return this.state<ChatErrorList>('chatErrors');
  }

  private get selectedGroup(): MessageGroup | null {
    return this.getter<MessageGroup | null>('selectedGroup');
  }

  private get status(): MessageBoxActions {
    return this.state<MessageBoxActions>('status');
  }

  private get unReadCount(): number {
    return this.getter<number>('unReadCount');
  }

  private get conversationSummary(): MessageBoxConversationSummary {
    return this.getter<MessageBoxConversationSummary>('summary');
  }

  private get isAgendaFeatureActivated(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_AGENDA_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_AGENDA_FEATURE).enabled;
  }

  private get localStatus(): number {
    if (this.forceStatus < 0) {
      return this.status;
    }
    return this.forceStatus;
  }

  private get conversationListTitle(): string {
    if (this.forceConversationListTitle) {
      return this.forceConversationListTitle;
    }

    return `${this.$t('chat.header.messages')}`;
  }

  private get exhibitorChatUser(): CommunityUser | null {
    // eslint-disable-next-line no-underscore-dangle
    if (this.selectedGroup && this.selectedGroup.target && this.selectedGroup.target.__typename === EntityType.EXHIBITOR) {
      if (this.selectedGroup.creatorUser && this.authUserIsModerator) {
        return this.selectedGroup.creatorUser;
      }
      const difference = (this.selectedGroup.users || []).filter(
        (item1) => !this.chatReps.some((item2) => item2 === item1.uid),
      );
      if (difference.length > 0) {
        return difference[0];
      }
      return null;
    }
    return null;
  }

  private get images(): string[] {
    if (this.selectedGroup && this.selectedGroup.target) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.selectedGroup.target.__typename === EntityType.SESSION) {
        return [FileResourceHelper
          .getFullPath((this.selectedGroup.target as Session).imageFileResource)];
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.selectedGroup.target.__typename === EntityType.EXHIBITOR && this.exhibitorChatUser) {
        return [this.exhibitorChatUser.profilePicture];
      }
    }
    return this.conversationSummary.images;
  }

  private get avatarNames(): Array<{ firstName: string; lastName: string }> {
    if (this.selectedGroup
      && ((this.selectedGroup.target
        // eslint-disable-next-line no-underscore-dangle
        && this.selectedGroup.target.__typename !== EntityType.SESSION) || !this.selectedGroup.target)) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.selectedGroup.target && this.selectedGroup.target.__typename === EntityType.EXHIBITOR && this.exhibitorChatUser) {
        return [{
          firstName: this.exhibitorChatUser.firstName as string,
          lastName: this.exhibitorChatUser.lastName as string,
        }];
      }
      return this.conversationSummary.fullUsers
        .map((u) => ({
          firstName: u.firstName as string,
          lastName: u.lastName as string,
        }));
    }

    return [];
  }

  private get title(): string {
    if (this.forceTitle) {
      return this.forceTitle;
    }
    if (this.selectedGroup && this.selectedGroup.target) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.selectedGroup.target.__typename === EntityType.SESSION) {
        return (this.selectedGroup.target as Session).name as string;
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.selectedGroup.target.__typename === EntityType.MEETING) {
        return (this.selectedGroup.target as Meeting).subject as string;
      }
    }
    if (this.selectedGroup && this.selectedGroup.activeMembersData.isUserAlone) {
      return this.$t('chat.content.messages.left-alone') as string;
    }

    if (this.isExhibitorConversation) {
      if (this.authUserIsModerator) {
        return this.selectedGroup && this.selectedGroup.creatorUser && this.selectedGroup.creatorUser.firstName
          ? `${this.selectedGroup.creatorUser.firstName} + ${this.companyName}`
          : '';
      }
      return this.companyName || '';
    }
    let users = this.conversationSummary.fullUsers.filter((user: CommunityUser) => user.uid !== this.authUser.uid);
    // eslint-disable-next-line no-underscore-dangle
    if (this.selectedGroup && this.selectedGroup.target?.__typename === EntityType.EXHIBITOR && this.exhibitorChatUser) {
      users = [this.exhibitorChatUser];
    }
    const tempConversationSummary = new MessageBoxConversationSummary(users);
    return `${tempConversationSummary.names.slice(0, 2)
      .join(', ')} ${this.conversationSummary.nbMembers > 2
      ? `+ ${(this.conversationSummary.nbMembers - 2)} ${this.$t('chat.header.others')}` : ''}`;
  }

  private get menuConfig(): MessageMenuItem[] {
    const configs: MessageMenuItem[] = [];
    if (this.selectedGroup) {
      if (this.selectedGroup.target
        // eslint-disable-next-line no-underscore-dangle
        && this.selectedGroup.target.__typename === EntityType.SESSION) {
        configs.push(...[
          MessageMenuItem.SESSION_PREVIEW,
          MessageMenuItem.VIEW,
          MessageMenuItem.LEAVE,
        ]);
      } else if (this.selectedGroup.target
        // eslint-disable-next-line no-underscore-dangle
        && this.selectedGroup.target.__typename === EntityType.EXHIBITOR) {
        configs.push(...[
          MessageMenuItem.MUTE,
          MessageMenuItem.VIEW,
          MessageMenuItem.LEAVE,
        ]);
      } else if (this.selectedGroup.target
        // eslint-disable-next-line no-underscore-dangle
        && this.selectedGroup.target.__typename === EntityType.MEETING) {
        configs.push(...[
          MessageMenuItem.MUTE,
          MessageMenuItem.VIEW,
        ]);
      } else {
        if (this.selectedGroup.groupType === GroupType.CONNECTION) {
          if (this.selectedGroup.users && this.selectedGroup.users.length > 1) {
            configs.push(MessageMenuItem.PROFILE);
          }
          configs.push(...[
            MessageMenuItem.MUTE,
            MessageMenuItem.ACCEPT,
            MessageMenuItem.DECLINE,
          ]);
        }
        if (this.selectedGroup.groupType === GroupType.DIRECT) {
          if (this.selectedGroup.users && this.selectedGroup.users.length > 1) {
            configs.push(MessageMenuItem.PROFILE);
          }
          configs.push(...[MessageMenuItem.ADD, MessageMenuItem.BLOCK, MessageMenuItem.DELETE]);
        }
        if (this.selectedGroup.groupType === GroupType.GROUP) {
          configs.push(...[MessageMenuItem.VIEW, MessageMenuItem.ADD, MessageMenuItem.LEAVE]);
        }
      }
      if (!this.selectedGroup.target
        && (this.selectedGroup.groupType === GroupType.GROUP
          || this.selectedGroup.groupType === GroupType.DIRECT)
        && this.isAgendaFeatureActivated
        && (this.selectedGroup.users || []).length > 1) {
        configs.push(MessageMenuItem.MEET);
      }
    }
    return configs;
  }

  private get isExhibitorConversation(): boolean {
    return !!(this.selectedGroup
      && this.selectedGroup.target
      // eslint-disable-next-line no-underscore-dangle
      && this.selectedGroup.target.__typename === EntityType.EXHIBITOR);
  }

  private get companyName(): string | null {
    if (this.isExhibitorConversation && this.selectedGroup) {
      return (this.selectedGroup.target as Exhibitor).name || null;
    }
    return null;
  }

  private get authUserIsModerator(): boolean {
    if (this.isExhibitorConversation) {
      if (this.isUnifyExhibitorPortal) {
        // eslint-disable-next-line no-underscore-dangle
        const moderator = this.chatReps.find((repUid) => repUid === this.authUser.uid);
        return !!moderator;
      }
      const messageGroupExhibitor = this.selectedGroup
        ? this.selectedGroup.target as Exhibitor
        : null;
      const authUserCanModerate = messageGroupExhibitor
        ? this.authUser.companyRoles
          .find((companyRole) => companyRole.company?.uid === messageGroupExhibitor.uid)
        : null;
      return !!authUserCanModerate;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  updated(): void {
    runMathJax();
  }

  private changeStatus(status: MessageBoxActions): void {
    return this.commit('changeStatus', status);
  }

  private toggleChat(close?: boolean): void {
    return this.commit('toggleChat', close);
  }

  private commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeName}/${name}`, payload);
  }

  private getter<T>(name: string): T {
    return this.$store.getters[`ChatDispatcherStore/${this.storeName}/${name}`];
  }

  private state<T>(name: string): T {
    return this.$store.state.ChatDispatcherStore[this.storeName][name];
  }

  private expandChat(): void {
    this.toggleChat();
    this.$eventsBus.emit('ontoolbox', {
      view: ToolbarMenuActions.TOOLBAR_CHAT,
    });
  }

  private onCreateMeeting(): void {
    if (this.selectedGroup) {
      this.$bvModal.hide('conversation-action-modal');
      this.$emit('close-conversation-action-modal');
      const participants: MeetingParticipant[] = [];
      (this.selectedGroup.users || [])
        .filter((u) => u.uid !== this.authUser.uid)
        .forEach((user: CommunityUser) => {
          participants.push({ user } as MeetingParticipant);
        });
      const creator = CommunityUser
        .hydrate(Object.fromEntries(Object.entries(this.authUser)
          .filter(([k]) => [
            'uid',
            'schemaCode',
            'firstName',
            'lastName',
            'name',
            'jobTitle',
            'employerName',
            'pictureFileResource'].includes(k))));
      const now = DateTimeHelper.roundToNearest15(DateTimeHelper.getCurrentDateTime());
      const startTime = DateTimeHelper.toISO8601(now);
      const endTime = DateTimeHelper.toISO8601(addHours(now, 1));
      const startTimestamp = getUnixTime(now);
      const endTimestamp = getUnixTime(addHours(now, 1));
      const meeting = {
        uid: 'new-meeting',
        creator,
        isCancelled: false,
        participants,
        startTime,
        endTime,
        startTimestamp,
        endTimestamp,
      } as unknown as Meeting;
      const event = AgendaStoreHelper.convertMeetingToEvent(meeting, this.selectedTzName);
      this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
      this.setViewMode(ViewMode.CREATE);
      this.setEventEdited(event as Partial<Event>);
    }
  }
}
