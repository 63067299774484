import CommunityUser from '@/models/graphql/CommunityUser';

class MessageBoxConversationSummary {
  private readonly users!: CommunityUser[];

  constructor(users: Array<CommunityUser>) {
    this.users = users;
  }

  get nbMembers(): number {
    return this.users ? this.users.length : 0;
  }

  get names(): string[] {
    return this.users ? this.users.map((item) => `${item.firstName}`) : [];
  }

  get fullNames(): string[] {
    return this.users ? this.users.map((item) => `${item.firstName} ${item.lastName}`) : [];
  }

  get images(): string[] {
    return this.users ? this.users.map((item) => (item.profilePicture ? item.profilePicture : ''))
      .sort((a, b) => (a < b ? 1 : -1)) : [];
  }

  get fullUsers(): CommunityUser[] {
    return this.users;
  }

  get isUserAlone(): boolean {
    return this.users.length < 1;
  }
}

export default MessageBoxConversationSummary;
