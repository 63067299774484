import { Action, Module, Mutation } from 'vuex-module-decorators';
import jwtDecode from 'jwt-decode';
import LoadableStore from '@/store/LoadableStore';
import RootState from '@/store/states/RootState';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import JwtParams from '@/utils/types/JwtParams';
import Logger from '@/utils/logger/Logger';
import CommunityUser from '@/models/graphql/CommunityUser';
import CookieService from '@/services/CookieService';
import AuthStep from '@/utils/enums/AuthStep';
import AccountManagementRepository from '@/repositories/AccountManagementRepository';
import EmailResponse from '@/utils/types/sign-in/EmailResponse';
import AccountServiceRepository from '@/repositories/AccountServiceRepository';
import SignInCanProceed from '@/utils/types/SignInCanProceed';
import EmailType from '@/utils/enums/EmailType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import CommunityUserRepository from '@/repositories/CommunityUserRepository';
import LoginResponseParams from '@/utils/types/LoginResponseParams';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';

/* eslint-disable no-underscore-dangle */

@Module({ namespaced: true })
export default class AuthenticationStore extends LoadableStore<CommunityUser> {
  authErrors: string[] = [];

  authToken = '';

  authStep: AuthStep | null = null;

  authEmail: string | null = null;

  signInCanProceed: SignInCanProceed | null = null;

  signInEmailState: EmailType | null = null;

  private accountManagementRepository = new AccountManagementRepository();

  private accountServiceRepository = new AccountServiceRepository();

  private communityUserRepository = new CommunityUserRepository();

  get isAuthenticated(): boolean {
    return !!this.context.rootState.authUser && !!CookieService.getCookie(AUTH_TOKEN);
  }

  @Action
  checkEmail(payload: { email: string }): Promise<EmailResponse> {
    this.context.commit('load', true);
    const token = CookieService.getCookie(AUTH_TOKEN);
    const { communityCode } = this.context.rootGetters;
    if (communityCode && token) {
      return this.accountServiceRepository
        .checkEmail(communityCode, payload.email, token)
        .then((response) => {
          if (
            response.success
            && response.canProceedWithAccountCreation !== undefined
            && response.canProceedWithPasswordLogin !== undefined
            && response.canProceedWithMagicLinkViaEmail !== undefined
          ) {
            this.context.commit('setAuthEmail', payload.email);
            this.context.commit('setSignInCanProceed', {
              accountCreation: response.canProceedWithAccountCreation,
              passwordLogin: response.canProceedWithPasswordLogin,
              magicLink: response.canProceedWithMagicLinkViaEmail,
            });
            return response;
          }
          return Promise.reject();
        })
        .catch(() => Promise.reject());
    }
    return Promise.reject();
  }

  @Action
  createAccount(model: CommunityUser): Promise<CommunityUser | undefined> {
    const { rootState } = this.context;
    return this.communityUserRepository.create({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: GqlEntityInputType.COMMUNITY_USER_INPUT,
        value: model,
      }]),
      fragmentName: 'communityUserCreateFragment',
    })
      .then((response) => {
        if (response?._authToken) {
          CookieService.setCookie(AUTH_TOKEN, response._authToken);
          rootState.authUser = response;
        }
        this.context.commit('setSignInEmailState', EmailType.WELCOME_EMAIL);
        return response;
      });
  }

  @Action
  login(credentials: {
    username: string;
    password: string;
  } | null = null): Promise<CommunityUser | null | undefined> {
    this.context.commit('load', true);
    const {
      rootState,
      rootGetters,
    } = this.context;

    if (credentials) {
      const {
        username,
        password,
      } = credentials;
      const token = CookieService.getCookie(AUTH_TOKEN);
      if (rootGetters.communityCode && token) {
        return this.accountServiceRepository.login(username, password, rootGetters.communityCode, token)
          .then((response): Promise<CommunityUser | null> => {
            if (response && response.success) {
              this.context.commit('authenticate', {
                response,
                rootState,
                remember: false,
              });
              const authResponse = response as unknown as LoginResponseParams;
              return this.communityUserRepository.authUser({
                definition: buildQueryDefinition({
                  filter: {
                    value: {
                      uid: authResponse.userUid,
                    },
                    type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
                  },
                }),
              })
                .then((res) => {
                  if (res) {
                    this.context.commit('setAuthStep', AuthStep.LOGGED_IN);
                    rootState.authUser = CommunityUser.hydrate(res);
                    this.context.commit('setAuthToken', response.communityToken);
                    Logger.prototype.log(['trackEvent', 'communityUser', 'signIn',
                      `${rootState.authUser.firstName} ${rootState.authUser.lastName}`, 0, {
                        ...Logger.prototype
                          .serialiseAuthUserDimensions(
                            rootState.authUser,
                            rootState.community?.code ?? '',
                            'communityUser',
                          ),
                        dimension2: this.context.rootState.i18n?.locale || '',
                      }]);
                    return res;
                  }

                  return null;
                });
            }

            if (!response.success) {
              return Promise.resolve(null);
            }
            return Promise.reject();
          })
          .catch(() => {
            this.context.commit('unAuthenticate', rootState);
            this.context.commit('authFailure', 'You have entered an invalid username or password');
            Logger.prototype.log([
              'trackEvent',
              'communityUser',
              'signInFailed',
              '',
              0,
              { dimension9: 'communityUser' },
            ]);
            return Promise.reject();
          });
      }
      return Promise.reject();
    }

    const token = CookieService.getCookie(AUTH_TOKEN) as string;
    const decodedToken = jwtDecode(token) as JwtParams;
    return this.communityUserRepository.authUser({
      definition: buildQueryDefinition({
        filter: {
          value: {
            uid: decodedToken.u,
          },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then(((res) => {
        if (res) {
          rootState.authUser = CommunityUser.hydrate(res);
          this.context.commit('setAuthToken', token);
          return rootState.authUser;
        }
        return Promise.resolve(null);
      }));
  }

  @Action
  logout(): Promise<void> {
    const { rootState } = this.context;
    this.context.commit('unAuthenticate', rootState);
    this.context.commit('mutateLoadingGuestToken', true, { root: true });
    // This method loads the domain info and sets the guest token to the authToken cookie
    return this.context.dispatch('loadCommunityCodeFromDomain', '', { root: true })
      .then((payload) => {
        this.context.commit('setAuthToken', payload.token);
        this.context.commit('load', false);
      });
  }

  @Action
  register(newUser: Partial<CommunityUser>): Promise<void | Response | string> {
    this.context.commit('load', true);
    const { rootGetters } = this.context;
    return this.context.dispatch(
      'CommunityUserStore/getForCommunityUserAuth',
      { filter: { email: newUser.email } },
      { root: true },
    )
      .then((response: CommunityUser | undefined) => {
        this.context.commit('load', false);
        if (response?.uid) {
          this.context.commit('authFailure', 'This email already exists.');
          return Promise.reject(new Error('This email already exists.'));
        }
        return this.accountManagementRepository.register(newUser, rootGetters.communityCode);
      })
      .catch((err) => {
        this.context.commit('authFailure', err);
        return err.message;
      });
  }

  @Action
  activateAccount(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const { communityCode } = this.context.rootGetters;
      const token = CookieService.getCookie(AUTH_TOKEN);
      if (communityCode && token) {
        this.accountServiceRepository.activateAccount(communityCode, token)
          .then((response) => {
            if (response && response.success) {
              this.context.dispatch('loginWithoutCredentials')
                .then(() => {
                  resolve(true);
                })
                .catch(() => {
                  resolve(false);
                });
            } else {
              resolve(false);
            }
          });
      } else {
        resolve(false);
      }
    });
  }

  @Action
  loginWithoutCredentials(): Promise<CommunityUser | null | undefined> {
    this.context.commit('load', true);
    const { rootState } = this.context;
    const token = CookieService.getCookie(AUTH_TOKEN) as string;
    const decodedToken = jwtDecode(token) as JwtParams;
    return this.communityUserRepository.authUser({
      definition: buildQueryDefinition({
        filter: {
          value: {
            uid: decodedToken.u,
          },
          type: GqlEntityFilterType.COMMUNITY_USER_FILTER,
        },
      }),
    })
      .then(((res) => {
        if (res) {
          rootState.authUser = CommunityUser.hydrate(res);
          this.context.commit('setAuthToken', token);
          return rootState.authUser;
        }
        return Promise.resolve(null);
      }));
  }

  @Action
  magicLink(payload: { username: string }): Promise<{ success: boolean } | null> {
    const { communityCode } = this.context.rootGetters;
    const token = CookieService.getCookie(AUTH_TOKEN);
    if (communityCode && token) {
      return this.accountServiceRepository.magicLink(communityCode, payload.username, token)
        .then((response) => {
          this.context.commit('setSignInEmailState', EmailType.EMAIL_LINK);
          return response;
        });
    }
    return Promise.resolve({ success: false });
  }

  @Action
  changeUserEmail(payload: { token: string }): Promise<boolean> {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-whitespace-before-property
      this.context.dispatch('CommunityUserStore/changeUserEmail', { uploadToken: payload.token }, { root: true })
        .then((response: CommunityUser | undefined) => {
          if (response) {
            if (response._authToken) {
              CookieService.setCookie(AUTH_TOKEN, response._authToken);
              this.context.commit('setAuthToken', response._authToken);
            }
            this.context.dispatch('login')
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  @Action
  updatePassword(payload: {
    tempToken: string;
    newPassword: string;
    uid: string;
    email?: string;
  }): Promise<boolean | null> {
    const params = {
      userUid: payload.uid,
      tempToken: payload.tempToken,
      newPassword: payload.newPassword,
    };
    return this.context.dispatch('CommunityUserStore/updatePasswordWithTempToken', params, { root: true })
      .then((response) => {
        if (response) {
          if (payload.email) {
            return this.context.dispatch('login', {
              username: payload.email,
              password: payload.newPassword,
            })
              .then(() => true)
              .catch(() => false);
          }
          if (this.context.rootState.authUser) {
            // eslint-disable-next-line no-underscore-dangle
            this.context.rootState.authUser._needsPasswordCreated = false;
          }
          return true;
        }
        return false;
      })
      .catch(() => false);
  }

  @Action
  sendTriggerChangeEmail(payload: { email: string }): Promise<boolean | null> {
    const {
      rootState,
      rootGetters,
    } = this.context;
    if (rootGetters.authUser.uid && payload.email) {
      payload.email = payload.email.toLowerCase();
      return this.context.dispatch(
        'CommunityUserStore/count',
        { filter: payload },
        { root: true },
      )
        .then((count) => {
          if (count === 0) {
            return this.context.dispatch(
              'CommunityUserStore/updateUserProfile',
              {
                email: payload.email,
              },
              { root: true },
            )
              .then((communityUser) => {
                if (communityUser && rootState.authUser) {
                  rootState.authUser.email = communityUser.email;
                  return true;
                }
                return null;
              })
              .catch(() => null);
          }
          return Promise.resolve(false);
        })
        .catch(() => Promise.resolve(null));
    }
    return Promise.resolve(null);
  }

  @Action
  triggerWelcome(payload: {
    communityCode: string;
    userId: string;
  }): void {
    this.context.commit('load', true);
    const {
      communityCode,
      userId,
    } = payload;
    this.accountManagementRepository.triggerWelcome(
      communityCode,
      userId,
    )
      .finally(() => this.context.commit('load', false));
  }

  @Action
  checkActivatability(payload: {
    token: string;
    email: string | null;
    password: string | null;
  }): Promise<object> {
    const {
      email,
      password,
      token,
    } = payload;

    return this.accountManagementRepository.checkActivatability(
      email,
      password,
      token,
    );
  }

  @Action
  triggerRecovery(payload: { email: string }): Promise<{ success: boolean } | null> {
    const { communityCode } = this.context.rootGetters;
    if (communityCode) {
      this.context.commit('load', true);
      return this.accountServiceRepository.resetPassword(communityCode, payload.email)
        .then((response) => {
          if (response && response.success) {
            this.context.commit('setSignInEmailState', EmailType.PASSWORD_RESET);
          }
          return response;
        })
        .catch(() => Promise.resolve({ success: false }));
    }
    return Promise.resolve({ success: false });
  }

  @Mutation
  setAuthStep(step: AuthStep | null): void {
    this.authStep = step;
  }

  @Mutation
  setAuthEmail(email: string | null): void {
    this.authEmail = email;
  }

  @Mutation
  setSignInEmailState(emailType: EmailType | null): void {
    this.signInEmailState = emailType;
  }

  @Mutation
  setSignInCanProceed(data: SignInCanProceed | null): void {
    this.signInCanProceed = data;
  }

  @Mutation
  setAuthToken(token: string): void {
    this.authToken = token;
  }

  @Mutation
  resetAuthErrors(): void {
    this.authErrors = [];
  }

  @Mutation
  authFailure(errors: string): void {
    if (this.authErrors.includes(errors)) {
      const index = this.authErrors.indexOf(errors);
      this.authErrors.splice(index, 1, errors);
    } else {
      this.authErrors = [...this.authErrors, errors];
    }
  }

  @Mutation
  // eslint-disable-next-line class-methods-use-this
  authenticate(params: {
    response: LoginResponseParams;
    rootState: RootState;
    remember: boolean;
  }): void {
    const { response } = params;
    if (response.communityToken) {
      if (params.remember) {
        CookieService.setCookie(AUTH_TOKEN, response.communityToken, 365);
      } else {
        CookieService.setCookie(AUTH_TOKEN, response.communityToken);
      }
    }
  }

  @Mutation
  unAuthenticate(rootState: RootState): void {
    // eslint-disable-next-line no-param-reassign
    rootState.authUser = null;
    CookieService.deleteCookie(AUTH_TOKEN);
    if (this.communityUserRepository) {
      this.communityUserRepository.resetStore();
    }
  }

  @Mutation
  afterLogin(payload: { rootState: RootState; token: string; communityUser: CommunityUser }): void {
    payload.rootState.authUser = CommunityUser.hydrate(payload.communityUser);
    this.authToken = payload.token;
  }
}
