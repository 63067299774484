











































import { Component, Prop } from 'vue-property-decorator';
import ToastActionType from '@/utils/enums/ToastActionType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VueContextLoading from '@/components/VueContextLoading';

@Component({
  components: { FontAwesomeComponent },
})
export default class ToastComponent extends VueContextLoading {
  @Prop({ default: -1 })
  private id!: number;

  @Prop({ default: undefined })
  private type!: ToastActionType;

  @Prop({ default: undefined })
  private item!: string;

  @Prop({ default: false })
  private undo!: boolean;

  @Prop({ default: '' })
  private message!: string;

  @Prop({ default: 5000 })
  private delay!: number;

  @Prop({ default: null })
  private retryCallback!: () => void;

  private show = true;

  private displayMessage = '';

  created(): void {
    this.setDisplayMessage();
    if (this.show) {
      setTimeout(() => {
        this.unShowToast();
      }, this.delay);
    }
  }

  private unShowToast(): void {
    this.show = false;
    this.$store.commit('ToastStore/removeActionsMade', this.id);
  }

  private setDisplayMessage(): void {
    switch (this.type) {
      case ToastActionType.BOOKMARK:
        this.displayMessage = this.$tc('toast-component.message.bookmark', 0, { itemName: this.item });
        break;
      case ToastActionType.UNBOOKMARK:
        this.displayMessage = this.$tc('toast-component.message.unbookmark', 0, { itemName: this.item });
        break;
      case ToastActionType.FOLLOW:
        this.displayMessage = this.$tc('toast-component.message.follow', 0, { itemName: this.item });
        break;
      case ToastActionType.UNFOLLOW:
        this.displayMessage = this.$tc('toast-component.message.unfollow', 0, { itemName: this.item });
        break;
      case ToastActionType.SAVE_CHANGES:
        this.displayMessage = this.$t('toast-component.message.save-changes')
          .toString();
        break;
      case ToastActionType.RESET_FILTERS:
        this.displayMessage = this.$t('toast-component.message.reset-filters')
          .toString();
        break;
      case ToastActionType.UPDATE_STATUS:
        this.displayMessage = this.$t('toast-component.message.update-status')
          .toString();
        break;
      case ToastActionType.UPDATE_AVATAR:
        this.displayMessage = this.$t('toast-component.message.update-avatar')
          .toString();
        break;
      case ToastActionType.REMOVE_AVATAR:
        this.displayMessage = this.$t('toast-component.message.remove-avatar')
          .toString();
        break;
      case ToastActionType.UPDATE_COVER_IMAGE:
        this.displayMessage = this.$t('toast-component.message.update-cover-image')
          .toString();
        break;
      case ToastActionType.REMOVE_COVER_IMAGE:
        this.displayMessage = this.$t('toast-component.message.remove-cover-image')
          .toString();
        break;
      case ToastActionType.SEND_VERIFICATION_EMAIL:
        this.displayMessage = this.$t('toast-component.message.send-verification-email')
          .toString();
        break;
      case ToastActionType.ACCEPT_CONNECTION_REQUEST:
        this.displayMessage = this.$tc('toast-component.message.accept-connection-request', 0, { userName: this.item });
        break;
      case ToastActionType.DECLINE_CONNECTION_REQUEST:
        this.displayMessage = this.$tc('toast-component.message.decline-connection-request', 0, { userName: this.item });
        break;
      case ToastActionType.SEND_CONNECTION_REQUEST:
        this.displayMessage = this.$t('toast-component.message.send-connection-request')
          .toString();
        break;
      case ToastActionType.REMOVE_CONNECTION_REQUEST:
        this.displayMessage = this.$tc('toast-component.message.remove-connection-request', 0, { userName: this.item });
        break;
      case ToastActionType.CANCEL_PENDING_REQUEST:
        this.displayMessage = this.$t('toast-component.message.cancel-pending-request')
          .toString();
        break;
      case ToastActionType.BLOCK_USER:
        this.displayMessage = this.$tc('toast-component.message.block-user', 0, { userName: this.item });
        break;
      case ToastActionType.UNBLOCK_USER:
        this.displayMessage = this.$tc('toast-component.message.unblock-user', 0, { userName: this.item });
        break;
      case ToastActionType.DELETE_MESSAGE:
        this.displayMessage = this.$t('toast-component.message.delete-message')
          .toString();
        break;
      case ToastActionType.DELETE_MEETING:
        this.displayMessage = this.$t('toast-component.message.delete-meeting')
          .toString();
        break;
      case ToastActionType.MEETING_REQUEST_SENT:
        this.displayMessage = this.$t('toast-component.message.meeting-request-sent')
          .toString();
        break;
      case ToastActionType.DELETE_NOTE:
        this.displayMessage = this.$t('toast-component.message.delete-note')
          .toString();
        break;
      case ToastActionType.UPDATE_URL:
        this.displayMessage = this.$t('toast-component.message.update-url')
          .toString();
        break;
      case ToastActionType.UPDATE_PERSONAL_INFORMATION:
        this.displayMessage = this.$t('toast-component.message.update-personal-information')
          .toString();
        break;
      case ToastActionType.UPDATE_COVER_IMAGE_ERROR:
        this.displayMessage = this.$t('toast-component.message.update-image-error')
          .toString();
        break;
      case ToastActionType.UPDATE_AVATAR_ERROR:
        this.displayMessage = this.$t('toast-component.message.update-image-error')
          .toString();
        break;
      case ToastActionType.UPDATE_COMPANY_INFORMATION:
        this.displayMessage = this.$t('toast-component.message.update-company-information')
          .toString();
        break;
      case ToastActionType.UPDATE_SPEAKER_INFORMATION:
        this.displayMessage = this.$t('toast-component.message.update-speaker-information')
          .toString();
        break;
      case ToastActionType.UPDATE_BANNER:
        this.displayMessage = this.$t('toast-component.message.update-banner')
          .toString();
        break;
      case ToastActionType.REMOVE_BANNER:
        this.displayMessage = this.$t('toast-component.message.remove-banner')
          .toString();
        break;
      case ToastActionType.UPDATE_LOGO:
        this.displayMessage = this.$t('toast-component.message.update-logo')
          .toString();
        break;
      case ToastActionType.REMOVE_LOGO:
        this.displayMessage = this.$t('toast-component.message.remove-logo')
          .toString();
        break;
      case ToastActionType.CHAT_ACTION_ERROR:
        this.displayMessage = this.$t('toast-component.message.chat-action-error')
          .toString();
        break;
      case ToastActionType.DELETE_PRODUCT:
        this.displayMessage = this.$t('toast-component.message.delete-product')
          .toString();
        break;
      case ToastActionType.UPDATE_PRODUCT:
        this.displayMessage = this.$t('toast-component.message.update-product')
          .toString();
        break;
      case ToastActionType.CREATE_PRODUCT:
        this.displayMessage = this.$t('toast-component.message.create-product')
          .toString();
        break;
      case ToastActionType.PUBLISH_PRODUCT:
        this.displayMessage = this.$t('toast-component.message.publish-product')
          .toString();
        break;
      case ToastActionType.UNPUBLISH_PRODUCT:
        this.displayMessage = this.$t('toast-component.message.unpublish-product')
          .toString();
        break;
      case ToastActionType.DELETE_DEAL:
        this.displayMessage = this.$t('toast-component.message.delete-deal')
          .toString();
        break;
      case ToastActionType.UPDATE_DEAL:
        this.displayMessage = this.$t('toast-component.message.update-deal')
          .toString();
        break;
      case ToastActionType.CREATE_DEAL:
        this.displayMessage = this.$t('toast-component.message.create-deal')
          .toString();
        break;
      case ToastActionType.UPDATE_HANDOUT:
        this.displayMessage = this.$t('toast-component.message.update-handout')
          .toString();
        break;
      case ToastActionType.CREATE_HANDOUT:
        this.displayMessage = this.$t('toast-component.message.create-handout')
          .toString();
        break;
      case ToastActionType.DELETE_HANDOUT:
        this.displayMessage = this.$t('toast-component.message.delete-handout')
          .toString();
        break;
      case ToastActionType.SETTINGS_CHANGE_EMAIL:
        this.displayMessage = this.$t('toast-component.message.setting-change-email')
          .toString();
        break;
      case ToastActionType.SETTINGS_CHANGE_PASSWORD:
        this.displayMessage = this.$t('toast-component.message.setting-change-password')
          .toString();
        break;
      case ToastActionType.PUBLISH_DEAL:
        this.displayMessage = this.$t('toast-component.message.publish-deal')
          .toString();
        break;
      case ToastActionType.UNPUBLISH_DEAL:
        this.displayMessage = this.$t('toast-component.message.unpublish-deal')
          .toString();
        break;
      case ToastActionType.UPDATE_COMPANY_USER_ROLE:
        this.displayMessage = this.$t('toast-component.message.update-company-user-role')
          .toString();
        break;
      case ToastActionType.DELETE_COMPANY_USER_ROLE:
        this.displayMessage = this.$t('toast-component.message.delete-company-user-role')
          .toString();
        break;
      case ToastActionType.CLIPBOARD_COPIED:
        this.displayMessage = this.$t('toast-component.message.clipboard-copied')
          .toString();
        break;
      case ToastActionType.DELETE_SELECTION:
        this.displayMessage = this.$t('toast-component.message.delete-selection')
          .toString();
        break;
      case ToastActionType.USER_INVITED:
        this.displayMessage = this.$t('toast-component.message.user-invited')
          .toString();
        break;
      case ToastActionType.USER_ACCEPT_INVITATION:
        this.displayMessage = this.$t('toast-component.message.user-accept-invitation')
          .toString();
        break;
      case ToastActionType.USER_DECLINE_INVITATION:
        this.displayMessage = this.$t('toast-component.message.user-decline-invitation')
          .toString();
        break;
      case ToastActionType.USER_ACCEPT_INVITATION_ERROR:
        this.displayMessage = this.$t('toast-component.message.user-accept-invitation-error')
          .toString();
        break;
      case ToastActionType.USER_DECLINE_INVITATION_ERROR:
        this.displayMessage = this.$t('toast-component.message.user-decline-invitation-error')
          .toString();
        break;
      case ToastActionType.UNBLOCK_USERS:
        this.displayMessage = this.$t('toast-component.message.unblock-users')
          .toString();
        break;
      case ToastActionType.LEFT_COMPANY:
        this.displayMessage = this.$tc('toast-component.message.left-company', 0, { companyName: this.item });
        break;
      case ToastActionType.COPY_SESSION_ID_TO_CLIPBOARD:
        this.displayMessage = this.$t('toast-component.message.copy-session-id-to-clipboard')
          .toString();
        break;
      case ToastActionType.COPY_TO_CLIPBOARD:
        this.displayMessage = this.$t('toast-component.message.copy-to-clipboard')
          .toString();
        break;
      case ToastActionType.FAILED_COPY_TO_CLIPBOARD:
        this.displayMessage = this.$t('toast-component.message.failed-copy-to-clipboard')
          .toString();
        break;
      case ToastActionType.COMMENT_DELETE_SUCCESS:
        this.displayMessage = this.$t('toast-component.message.comment-delete-success')
          .toString();
        break;
      case ToastActionType.COMMENT_DELETE_FAIL:
        this.displayMessage = this.$t('toast-component.message.comment-delete-fail')
          .toString();
        break;
      case ToastActionType.UPDATE_SESSION:
        this.displayMessage = this.$t('toast-component.message.update-session')
          .toString();
        break;
      case ToastActionType.TAG_RENAMED:
        this.displayMessage = this.$t('toast-component.message.tag-renamed')
          .toString();
        break;
      case ToastActionType.TAG_REMOVED:
        this.displayMessage = this.$t('toast-component.message.tag-removed')
          .toString();
        break;
      case ToastActionType.TAG_DELETED:
        this.displayMessage = this.$t('toast-component.message.tag-deleted')
          .toString();
        break;
      case ToastActionType.TAGS_UPDATED:
        this.displayMessage = this.$t('toast-component.message.tags-updated')
          .toString();
        break;
      case ToastActionType.SHARE_CONTACT:
        this.displayMessage = this.$t('toast-component.message.contact-information-sent')
          .toString();
        break;
      case ToastActionType.FEEDBACK_SENT:
        this.displayMessage = this.$t('toast-component.message.feedback-sent')
          .toString();
        break;
      case ToastActionType.REPOST:
        this.displayMessage = this.$t('toast-component.message.repost')
          .toString();
        break;
      case ToastActionType.REPOST_TOPIC:
        this.displayMessage = this.$t('toast-component.message.repost-topic')
          .toString();
        break;
      case ToastActionType.EMAIL_SENT:
        this.displayMessage = this.$t('toast-component.message.email-sent')
          .toString();
        break;
      case ToastActionType.FAILED_TO_EXPORT_AGENDA:
        this.displayMessage = this.$t('toast-component.message.failed-export-agenda')
          .toString();
        break;
      case ToastActionType.SENT_MESSAGE:
        this.displayMessage = this.$t('toast-component.message.sent-message')
          .toString();
        break;
      case ToastActionType.CREATE_EXHIBITOR_VIDEO:
        this.displayMessage = this.$t('toast-component.message.create-exhibitor-video')
          .toString();
        break;
      case ToastActionType.UPDATE_EXHIBITOR_VIDEO:
        this.displayMessage = this.$t('toast-component.message.update-exhibitor-video')
          .toString();
        break;
      case ToastActionType.DELETE_EXHIBITOR_VIDEO:
        this.displayMessage = this.$t('toast-component.message.delete-exhibitor-video')
          .toString();
        break;
      case ToastActionType.CREATE_EXHIBITOR_VIDEO_ERROR:
        this.displayMessage = this.$t('toast-component.message.create-exhibitor-video-error')
          .toString();
        break;
      default:
        this.displayMessage = this.message;
        break;
    }
  }
}
