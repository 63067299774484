import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import CompanyUserRoleRepository from '@/repositories/CompanyUserRoleRepository';
import Role from '@/models/graphql/Role';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import LoadableStore from '@/store/LoadableStore';
import RoleRepository from '@/repositories/RoleRepository';
import { CommunityUserPermissions } from '@/utils/types/CommunityUserPermissions';

interface CompanyUserRoleState extends LoadableState {
  companyUserRoles: CompanyUserRole[];
  companyUserRole: CompanyUserRole;
  companyUserRoleCreated: CompanyUserRole;
  saveIsLoading: boolean;
}

@Module({ namespaced: true })
export default class CompanyUserRoleStore
  extends LoadableStore<CompanyUserRoleState> {
  companyUserRoles: CompanyUserRole[] = [] as CompanyUserRole[];

  companyUserRole: CompanyUserRole = {} as CompanyUserRole;

  companyUserRoleCreated: CompanyUserRole = {} as CompanyUserRole;

  saveIsLoading = false;

  private readonly companyUserRoleRepository = new CompanyUserRoleRepository();

  private readonly roleRepository = new RoleRepository();

  get fetchCompanyUserRoles(): CompanyUserRole[] {
    return this.companyUserRoles;
  }

  get fetchCompanyUserRole(): CompanyUserRole {
    return this.companyUserRole;
  }

  get fetchCompanyUserRoleCreated(): CompanyUserRole {
    return this.companyUserRoleCreated;
  }

  get saveLoading(): boolean {
    return this.saveIsLoading;
  }

  protected get repository(): CompanyUserRoleRepository {
    return this.companyUserRoleRepository;
  }

  @Action
  getOneWithParams(payload: { filter: object }): Promise<void | CompanyUserRole | undefined> {
    return this.repository.get({
      operationName: 'getOneWithParams',
      definition: buildQueryDefinition({
        filter: {
          value: payload.filter,
          type: GqlEntityFilterType.COMPANY_USER_ROLE_FILTER,
        },
      }),
    });
  }

  @Action
  getRoleByCode(code: string): Promise<void | Role | undefined> {
    return this.roleRepository.get({
      definition: buildMutationDefinition([{
        fieldName: 'code',
        type: 'String',
        value: code,
      }]),
    }).then((responce) => responce);
  }

  @Action
  createCompanyUserRole(payload: {
    userId: string;
    companyId: string;
    role: string;
    moderator?: boolean;
    canPost?: boolean;
    handleMeetings?: boolean;
    manageSales?: boolean;
    manageContent?: boolean;
    manageCompanyUserRoles?: boolean;
  }): Promise<void> {
    this.context.commit('load', true);
    this.context.commit('loadingSave', true);

    return this.roleRepository.get({
      definition: buildMutationDefinition([{
        fieldName: 'code',
        type: 'String',
        value: payload.role,
      }]),
    }).then((role) => {
      if (role) {
        return this.repository.create({
          definition: buildMutationDefinition([{
            fieldName: 'user_CommunityUserUid',
            type: 'ID',
            value: payload.userId,
          },
          {
            fieldName: 'role_RoleUid',
            type: 'ID',
            value: role.uid,
          },
          {
            fieldName: 'company_ExhibitorUid',
            type: 'ID',
            value: payload.companyId,
          },
          {
            fieldName: 'entity',
            type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
            value: {
              state: 'INVITED',
              moderator: payload.moderator,
              canPost: payload.canPost,
              handleMeetings: payload.handleMeetings,
              manageSales: payload.manageSales,
              manageContent: payload.manageContent,
              manageCompanyUserRoles: payload.manageCompanyUserRoles,
            },
          },
          ]),
        }).then((companyUserRole) => {
          if (companyUserRole && (companyUserRole as CompanyUserRole).uid) {
            this.context.commit('setCompanyUserRoleCreated', companyUserRole);
            this.context.commit('load', false);
            this.context.commit('loadingSave', false);
          }
        });
      }
      return Promise.resolve();
    });
  }

  @Action
  updateCompanyUserRole(payload: {
    companyUserRoleId: string;
    roleId: string;
  }): Promise<void | CompanyUserRole | undefined> {
    return this.repository.updateCompanyUserRole({
      definition: buildMutationDefinition([{
        fieldName: 'uid',
        type: 'ID',
        value: payload.companyUserRoleId,
      },
      {
        fieldName: 'role_RoleUid',
        type: 'ID',
        value: payload.roleId,
      },
      ]),
    });
  }

  @Action
  updateCompanyUserRoleSales(payload: { uid: string; manageSales: boolean }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'UpdateCompanyUserRoleSales',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.uid,
          manageSales: payload.manageSales,
          defaultCompany: false,
        },
      },
      ]),
    });
  }

  @Action
  updateCompanyUserRoleState(payload: {
    uid: string;
    state: CompanyUserRoleStatusType;
  }): Promise<CompanyUserRole | undefined> {
    this.context.commit('loadingSave', true);
    return this.repository.update({
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.uid,
          state: payload.state,
          defaultCompany: false,
        },
      },
      ]),
    }).then((response) => {
      this.context.commit('loadingSave', false);
      if (response.state === 'ACCEPTED' && this.context.rootState.authUser && this.context.rootState.authUser?.companyRoles) {
          // eslint-disable-next-line no-unused-expressions
          this.context.rootState.authUser?.companyRoles?.push(response);
      }
      if (
        this.context.rootState.authUser
          && this.context.rootState.authUser.companyRoles.length > 0
          && this.context.rootState.authUser.companyRoles.filter((
            companyRole: CompanyUserRole,
          ) => companyRole.defaultCompany === true).length === 0) {
        this.context.dispatch('setDefaultCompany', {
          userUid: this.context.rootState.authUser.uid,
          companyUserRoleUid: this.context.rootState.authUser.companyRoles[0].uid,
        });
      }
      return response;
    });
  }

  @Action
  setModerator(payload: {
    companyUserRoleId: string;
    moderator: boolean;
  }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setModerator',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          moderator: payload.moderator,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setPosting(payload: {
    companyUserRoleId: string;
    canPost: boolean;
  }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setPosting',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          canPost: payload.canPost,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setManageMeetings(payload: {
    companyUserRoleId: string;
    handleMeetings: boolean;
  }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setManageMeetings',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          handleMeetings: payload.handleMeetings,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setManageSales(payload: {
    companyUserRoleId: string;
    manageSales: boolean;
  }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setManageSales',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          manageSales: payload.manageSales,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setManageContent(payload: {
    companyUserRoleId: string;
    manageContent: boolean;
  }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setManageContent',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          manageContent: payload.manageContent,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setManageCompanyUserRoles(payload: {
    companyUserRoleId: string;
    manageCompanyUserRoles: boolean;
  }): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setManageCompanyUserRoles',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          manageCompanyUserRoles: payload.manageCompanyUserRoles,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setCompanyUserPermissions(payload: CommunityUserPermissions): Promise<CompanyUserRole | undefined> {
    return this.repository.update({
      operationName: 'setManageCompanyUserPermissions',
      definition: buildMutationDefinition([{
        fieldName: 'entity',
        type: `${GqlEntityInputType.COMPANY_USER_ROLE_INPUT}`,
        value: {
          uid: payload.companyUserRoleId,
          moderator: payload.moderator,
          canPost: payload.canPost,
          handleMeetings: payload.handleMeetings,
          manageSales: payload.manageSales,
          manageContent: payload.manageContent,
          manageCompanyUserRoles: payload.manageCompanyUserRoles,
        },
      },
      ]),
      fragmentName: 'companyUserRoleFullFragment',
    });
  }

  @Action
  setDefaultCompany(payload: {
    userUid: string;
    companyUserRoleUid: string;
  }): Promise<void | CompanyUserRole | undefined> {
    return this.repository.setDefaultCompany({
      definition: buildMutationDefinition([{
        fieldName: 'companyUserRoleUid',
        type: 'ID!',
        value: payload.companyUserRoleUid,
      },
      {
        fieldName: 'userUid',
        type: 'ID!',
        value: payload.userUid,
      },
      ]),
    });
  }

  @Action
  getAllCompanyUserRoles(payload: { userId: string; schemaCode: string }): Promise<void | CompanyUserRole> {
    const schemaCode = { schemaCode: (payload.schemaCode) ? payload.schemaCode : undefined };
    /* eslint-disable @typescript-eslint/camelcase */
    const baseFilters = payload.userId ? { user_some: { uid: payload.userId } } : {};
    const invitationRequest = { state: CompanyUserRoleStatusType.INVITED };
    const filter = {
      ...schemaCode,
      ...baseFilters,
      ...invitationRequest,
    };
    return this.repository.filter({
      operationName: 'LoadCompanyUserRoles',
      definition: buildQueryDefinition({
        filter: {
          value: filter,
          type: GqlEntityFilterType.COMPANY_USER_ROLE_FILTER,
        },
      }),
    }).then((response) => {
      if (response && response.length) {
        this.context.commit('setElements', response);
      }
    });
  }

  @Mutation
  setElements(list: CompanyUserRole[]): void {
    this.companyUserRoles = list;
  }

  @Mutation
  setElement(companyUserRole: CompanyUserRole): void {
    this.companyUserRole = companyUserRole;
  }

  @Mutation
  setCompanyUserRoleCreated(companyUserRole: CompanyUserRole): void {
    this.companyUserRoleCreated = companyUserRole;
  }

  @Mutation
  pushCompanyUserRole(companyUserRole: CompanyUserRole): void {
    const foundIndex = this.companyUserRoles.findIndex((c) => c.uid === companyUserRole.uid);
    if (foundIndex > -1) {
      this.companyUserRoles.splice(foundIndex, 1, companyUserRole);
    } else {
      this.companyUserRoles.unshift(companyUserRole);
    }
  }

  @Mutation
  removeCompanyUserRole(companyUserRole: CompanyUserRole): void {
    const foundIndex = this.companyUserRoles.findIndex((c) => c.uid === companyUserRole.uid);
    if (foundIndex > -1) {
      this.companyUserRoles.splice(foundIndex, 1);
    }
  }

  @Mutation
  protected loadingSave(isLoading: boolean): void {
    this.saveIsLoading = isLoading;
  }
}
