



























































































































































































































import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import Event from '@/utils/types/Event';
import { format } from 'date-fns-tz';
import { Getter, namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import Component, { mixins } from 'vue-class-component';
import PillWidget from '@/components/pill/PillWidget.vue';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import DynamicTextComponent from '@/components/DynamicTextComponent.vue';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ExportToCalendarComponent from '@/components/ExportToCalendarComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import MessageGroup from '@/models/graphql/MessageGroup';
import GUUID from '@/utils/GUUID';
import GroupType from '@/utils/enums/chat/GroupType';
import { CalendarEvent } from 'calendar-link';
import { Provide, Watch } from 'vue-property-decorator';
import { isAfter, startOfDay } from 'date-fns';
import Meeting from '@/models/graphql/Meeting';
import ToastActionParams from '@/utils/types/ToastActionParams';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import EntityType from '@/utils/enums/EntityType';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';

const agendaStore = namespace('AgendaStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    PillWidget,
    DynamicTextComponent,
    MessageBoxMessages,
    FontAwesomeComponent,
    ExportToCalendarComponent,
    ButtonComponent,
    AvatarSoloWidget,
  },
})
export default class MeetingDetail extends mixins(
  BreakpointWrapper,
  ChatModuleHelper,
  VueBaseNotify,
) {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @agendaStore.Getter
  private readonly fetchEventDetail!: Event;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  @agendaStore.Action
  private updateMeeting!: (payload: {
    meeting: Partial<Meeting>;
    toAdd?: string[];
    toDelete?: string[];
  }) => Promise<void>;

  @agendaStore.Action
  private updateMeetingParticipantState!: (payload: {
    meetingParticipantUid: string;
    state: string;
  }) => Promise<void>;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @Provide('context')
  private context = 'AgendaStore';

  @State
  private readonly dateLocale!: Locale;

  @State
  private selectedTzAbbreviation!: string;

  @State
  private authUser!: CommunityUser;

  @State
  private community!: Community;

  private tempGroupId = GUUID.uuidv4();

  private editClicked = false;

  private messageGroup: MessageGroup | null = null;

  private ViewMode = ViewMode;

  private MeetingParticipantState = MeetingParticipantState;

  private localView: 'detail' | 'chat' = 'detail';

  private get isMeetingChatFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled
      && (this.featureByKey(FeatureKeys.COMMUNITY_MEETING_CHAT)
        && this.featureByKey(FeatureKeys.COMMUNITY_MEETING_CHAT).enabled);
  }

  private get currentMeetingParticipantUid(): string | null {
    if (this.fetchEventDetail) {
      if ('participants' in this.fetchEventDetail
        && this.fetchEventDetail.participants
        && this.fetchEventDetail.participants.length > 0) {
        const state = (this.fetchEventDetail.participants as Array<MeetingParticipant>)
          .find((p) => p.user && p.user.uid === this.authUser.uid);
        if (state && state.uid) {
          return state.uid;
        }
      }
    }
    return null;
  }

  private get myMeetingState(): MeetingParticipantState | null {
    if (this.fetchEventDetail) {
      if ('participants' in this.fetchEventDetail
        && this.fetchEventDetail.participants
        && this.fetchEventDetail.participants.length > 0) {
        const state = (this.fetchEventDetail.participants as Array<MeetingParticipant>)
          .find((p) => p.user && p.user.uid === this.authUser.uid);
        if (state && state.state) {
          return state.state;
        }
      }
    }
    return null;
  }

  private get date(): string | { d: string; t: string } {
    const std = this.fetchEventDetail.tzStartTime;
    const edd = this.fetchEventDetail.tzEndTime;
    if (std && edd) {
      const zone = this.selectedTzAbbreviation;
      return {
        d: `${format(std, this.$t('app.date.defaultDateFormat')
          .toString(), { locale: this.dateLocale })}`,
        t: `${format(
          std,
          this.$t('app.date.defaultTimeFormat')
            .toString(),
          { locale: this.dateLocale },
        )} — ${format(
          edd,
          this.$t('app.date.defaultTimeFormat')
            .toString(),
          { locale: this.dateLocale },
        )}${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
        || !this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE).enabled ? ` (${zone})` : ''}`,
      };
    }
    return '';
  }

  private get isEditable(): boolean {
    return isAfter(this.fetchEventDetail.tzStartTime, startOfDay(DateTimeHelper.getCurrentDateTime()))
      && this.isCreator;
  }

  private get isCreator(): boolean {
    if (this.fetchEventDetail) {
      if ('creator' in this.fetchEventDetail && this.fetchEventDetail.creator && this.fetchEventDetail.creator.uid) {
        return this.fetchEventDetail.creator.uid === this.authUser.uid;
      }
    }
    return false;
  }

  private get addToCalendarEvent(): CalendarEvent {
    let guests: string[] = [];
    guests = (this.userEntityList as MeetingParticipant[])
      .filter((p) => p.user && p.user.email)
      .map((p) => `${p.user?.email}`);

    return {
      title: this.fetchEventDetail.title,
      description: this.fetchEventDetail.message,
      location: this.fetchEventDetail.location,
      start: this.fetchEventDetail?.startTime ? this.fetchEventDetail?.startTime : '',
      end: this.fetchEventDetail?.endTime ? this.fetchEventDetail?.endTime : '',
      guests,
    };
  }

  private get userEntityList(): Array<MeetingParticipant> {
    if (this.fetchEventDetail.participants
      && this.fetchEventDetail.participants
      && this.fetchEventDetail.participants.length > 0) {
      return [{
        uid: this.fetchEventDetail.creator?.uid,
        state: MeetingParticipantState.ACCEPTED,
        user: this.fetchEventDetail.creator,
      } as MeetingParticipant,
      ...this.fetchEventDetail.participants];
    }
    return [];
  }

  beforeDestroy(): void {
    if (!this.editClicked) {
      this.setViewMode(ViewMode.DAILY_VIEW);
    }
  }

  created(): void {
    this.notifyEvents = [NotificationEventType.MEETING_PARTICIPANT];
    this.storeContext = 'MeetingChatStore';
  }

  notificationCallback(event: SubscriptionEvent): void {
    const notification = Notification.hydrate(event.data);
    if (['MEETING_ACCEPTED', 'MEETING_DECLINED'].includes(notification.action as string)
      && notification
      && notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && notification.triggered.__typename === EntityType.MEETING_PARTICIPANT) {
      const meetingParticipant = notification.triggered as MeetingParticipant;
      if (meetingParticipant.meeting
        && this.fetchEventDetail.uid === meetingParticipant.meeting.uid
        && this.fetchEventDetail.participants) {
        const foundParticipant = (this.fetchEventDetail.participants as MeetingParticipant[])
          .find((p) => p.uid === meetingParticipant.uid);
        if (foundParticipant) {
          foundParticipant.state = meetingParticipant.state;
        }
      }
    }
  }

  private toggleLocalView(): void {
    if (this.localView === 'chat') {
      this.localView = 'detail';
    } else {
      this.localView = 'chat';
    }
  }

  @Watch('breakpoint.value')
  private resetLocalView(): void {
    if (!this.isMobile) {
      this.localView = 'detail';
    }
  }

  @Watch('chatStoreCreated')
  private setMeetingChat(): void {
    if (this.chatStoreCreated) {
      this.lookupForMessageGroupWithTarget(this.fetchEventDetail.entityId)
        .then((response) => {
          if (response) {
            this.setSelectedGroup(response.uid);
            this.changeStatus(MessageBoxActions.MESSAGES);
          } else if (this.fetchEventDetail.participants) {
            this.messageGroup = MessageGroup.hydrate({
              uid: this.tempGroupId,
              tempId: this.tempGroupId,
              groupType: GroupType.GROUP,
              users: [this.fetchEventDetail.creator,
                ...(this.fetchEventDetail.participants as MeetingParticipant[]).map(
                  (p: MeetingParticipant) => (p as MeetingParticipant).user,
                ) as CommunityUser[],
              ],
              target: {
                ...this.fetchEventDetail,
                __typename: EntityType.MEETING,
              },
              messages: [],
              updated: true,
              selected: true,
            });
            this.setNewConversation(this.messageGroup);
            this.setSelectedGroup(this.messageGroup.uid);
          }
        });
    }
  }

  private lookupForMessageGroupWithTarget(targetId: string): Promise<MessageGroup | undefined> {
    return this.$store.dispatch(`${this.storeContextPath}/lookupForMessageGroupWithTarget`, targetId);
  }

  private setNewConversation(group: object): void {
    this.$store.commit(`${this.storeContextPath}/setNewConversation`, group);
  }

  private setSelectedGroup(group: string | null): void {
    this.$store.commit(`${this.storeContextPath}/setSelectedGroup`, group);
  }

  private changeStatus(status: MessageBoxActions): void {
    this.$store.commit(`${this.storeContextPath}/changeStatus`, status);
  }

  private toggleChat(close?: boolean): void {
    this.$store.commit(`${this.storeContextPath}/toggleChat`, close);
  }

  private cancelMeeting(): void {
    if (this.fetchEventDetail) {
      this.updateMeeting({
        meeting: {
          uid: this.fetchEventDetail.uid,
          isCancelled: true,
        },
      })
        .then(() => this.setViewMode(ViewMode.DAILY_VIEW));
      this.addNewAction({
        message: `${this.$t('toolbox.agenda.create-edit.removed-from-agenda')}`,
        delay: 3500,
      });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.fetchEventDetail.entityType,
        StatLoggerActions.REMOVE_FROM_AGENDA,
        'removeAppointment',
        -1,
        this.fetchEventDetail.entityId,
        StatLoggerCategories.REMOVE,
        this.$i18n.locale,
      );
    }
  }

  private onAccept(): void {
    if (!this.isCreator
      && this.currentMeetingParticipantUid
      && this.myMeetingState !== MeetingParticipantState.ACCEPTED) {
      const found = (this.fetchEventDetail.participants as Array<MeetingParticipant>)
        .find((p) => p.uid === this.currentMeetingParticipantUid);
      const prevState = this.myMeetingState;
      if (found) {
        found.state = MeetingParticipantState.ACCEPTED;
      }
      this.updateMeetingParticipantState({
        meetingParticipantUid: this.currentMeetingParticipantUid,
        state: MeetingParticipantState.ACCEPTED,
      })
        .catch(() => {
          if (found && prevState) {
            found.state = prevState;
          }
        });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.fetchEventDetail.entityType,
        StatLoggerActions.ACCEPT_TO_AGENDA,
        'acceptAppointment',
        -1,
        this.fetchEventDetail.entityId,
        StatLoggerCategories.ACCEPT,
        this.$i18n.locale,
      );
    }
  }

  private onDecline(): void {
    if (!this.isCreator
      && this.currentMeetingParticipantUid
      && this.myMeetingState !== MeetingParticipantState.DECLINED) {
      const found = (this.fetchEventDetail.participants as Array<MeetingParticipant>)
        .find((p) => p.uid === this.currentMeetingParticipantUid);
      const prevState = this.myMeetingState;
      if (found) {
        found.state = MeetingParticipantState.DECLINED;
      }
      this.updateMeetingParticipantState({
        meetingParticipantUid: this.currentMeetingParticipantUid,
        state: MeetingParticipantState.DECLINED,
      })
        .catch(() => {
          if (found && prevState) {
            found.state = prevState;
          }
        });
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code as string,
        this.fetchEventDetail.entityType,
        StatLoggerActions.REJECT_FROM_AGENDA,
        'rejectAppointment',
        -1,
        this.fetchEventDetail.entityId,
        StatLoggerCategories.REJECT,
        this.$i18n.locale,
      );
    }
  }

  private jobDescriptionText(item: CommunityUser): string {
    if (item.employerName) {
      return this.$tc('toolbox.agenda.detail.content-at', 0, {
        job: item.jobTitle,
        employer: item.employerName,
      })
        .toString();
    }
    return (item.jobTitle) ? item.jobTitle?.toString() : '';
  }

  private updateCurrentState(state: MeetingParticipantState): void {
    if (!this.isCreator
      && this.fetchEventDetail
      && 'participants' in this.fetchEventDetail
      && this.fetchEventDetail.participants
      && this.fetchEventDetail.participants.length > 0) {
      const participant = (this.fetchEventDetail.participants as Array<MeetingParticipant>)
        .find((p) => p.user && p.user.uid === this.authUser.uid);
      if (participant && participant.state) {
        this.$set(participant, 'state', state);
      }
    }
  }

  private onEdit(): void {
    this.editClicked = true;
    this.setViewMode(ViewMode.EDIT);
    this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(this.fetchEventDetail.tzStartTime));
    this.setEventEdited(this.fetchEventDetail);
  }
}
