var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"b-hover",rawName:"v-b-hover",value:(function (value) {_vm.isHovered = value;}),expression:"(value) => {isHovered = value;}"}],class:[
    'message-box-list-item d-flex align-items-center position-relative',
    { 'selected' : _vm.isSelectable && _vm.selected },
    { 'bg-blue-b-1-light-blue' : _vm.isActive },
    { 'py-3' : _vm.isBasicLayout },
    { 'p-3' : !_vm.isBasicLayout } ],on:{"click":_vm.manageClick}},[(_vm.isSelectable)?_c('div',{staticClass:"checkbox"},[_c('b-form-checkbox',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_c('div',{staticClass:"avatars d-flex align-items-center justify-content-center position-relative"},[_c('solo-double-avatar-component',{attrs:{"images":_vm.images,"names":_vm.avatarNames,"type":_vm.data
        && _vm.data.target
        && _vm.data.target.__typename === _vm.EntityType.SESSION
        ? _vm.data.target.__typename
        : (_vm.data.target && _vm.data.target.__typename === _vm.EntityType.EXHIBITOR
          ? _vm.EntityType.EXHIBITOR
          : _vm.EntityType.USER)}}),(_vm.data.groupType === _vm.GroupType.CONNECTION)?_c('button-icon-component',{staticClass:"position-absolute w-fit border-0",staticStyle:{"right":"-0.25rem","bottom":"-0.25rem"},attrs:{"shape":"round","size":"md","variant":"primary"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":"far fa-user-plus","icon-classes":"icon-12"}})]},proxy:true}],null,false,934602923)}):_vm._e()],1),_c('div',{staticClass:"content d-flex flex-column flex-1 overflow-x-hidden"},[_c('div',{staticClass:"title d-flex align-items-center justify-content-between"},[_c('p',{class:['paragraph-2 m-0 text-neutral-n-8-dark-ink text-left ellipsis',
                 {'font-weight-bold': _vm.data.unreadState}]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.data.lastMessage)?_c('div',{staticClass:"d-flex align-items-center column-gap-2"},[_c('p',{staticClass:"paragraph-3 m-0 bold text-neutral-n-6-label\n          text-right text-nowrap text-capitalize"},[_vm._v(" "+_vm._s(_vm.receiveDateTime)+" ")]),(_vm.data.unreadState)?_c('span',{staticClass:"unread"}):_vm._e()]):_vm._e()]),_c('span',{class:['message label label-3 m-0 text-left ellipsis',
               _vm.data.unreadState
                 ? 'font-weight-bold text-neutral-n-8-dark-ink'
                 : 'text-neutral-n-6-label'
      ]},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isHovered && _vm.showActions)?_c('div',{staticClass:"actions rounded-circle bg-white-white-100"},[_c('message-box-menu-component',_vm._g({key:_vm.data.uid,attrs:{"flip-menu":_vm.flipMenuOptions,"group":_vm.data,"menu-config":_vm.menuConfig,"title":_vm.title,"users":_vm.users},on:{"update:flipMenu":function($event){_vm.flipMenuOptions=$event},"update:flip-menu":function($event){_vm.flipMenuOptions=$event},"remove-hover-state":function($event){_vm.isHovered = false;},"create-meeting":_vm.onCreateMeeting}},_vm.$listeners))],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }