/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import Notification from '@/models/graphql/Notification';
import { ApolloQueryResult, InMemoryCache } from '@apollo/client';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import GraphQlClients from '@/utils/enums/GraphQlClients';
import NotificationApolloClient from '@/plugins/apollo/NotificationApolloClient';
import SubscriptionParams from '@/utils/types/SubscriptionParams';
import SubscriptionBaseRepository from '@/repositories/SubscriptionBaseRepository';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import FilterInterface from '@/repositories/base/FilterInterface';
import { buildMutationGql, buildQueryGql, buildSubscriptionGql } from '@/graphql/_Tools/GqlGeneric';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import WriteInterface from '@/repositories/base/WriteInterface';
import GqlSubscriptionDefinition from '@/graphql/_Tools/GqlSubscriptionDefinition';
import GetterInterface from '@/repositories/base/GetterInterface';

export default class NotificationRepository extends SubscriptionBaseRepository<Notification>
  implements WriteInterface<Notification>, FilterInterface<Notification>, GetterInterface<Notification> {
  constructor() {
    super();

    this.apolloProvider.clients.set(GraphQlClients.NOTIFICATION_CLIENT, NotificationApolloClient.init(new InMemoryCache()));
  }

  eventSubscribe(payload: SubscriptionParams): Promise<{ unsubscribe: () => void }> {
    const params = payload.params as GqlPayloadParams;
    return buildSubscriptionGql({
      operationName: params.operationName || 'EventsSubscription',
      operation: 'notificationEvents',
      gqlDefinition: params.definition as GqlSubscriptionDefinition,
      fragmentName: 'subscriptionNotificationEventsFragment',
    })
      .then((subscription) => this.use(GraphQlClients.NOTIFICATION_CLIENT)
        .subscribe<{
          notificationEvents: SubscriptionEvent;
        }>(subscription, params.definition.variables)
        .subscribe({
          next: (value) => payload.callback(value.data?.notificationEvents || null),
        }));
  }

  genericEventSubscribe(payload: SubscriptionParams): Promise<{ unsubscribe: () => void }> {
    const params = payload.params as GqlPayloadParams;
    return buildSubscriptionGql({
      operationName: params.operationName || 'EventsSubscription',
      operation: 'genericNotificationEvents',
      gqlDefinition: params.definition as GqlSubscriptionDefinition,
      fragmentName: 'subscriptionNotificationGenericEventsFragment',
    })
      .then((subscription) => this.use(GraphQlClients.NOTIFICATION_CLIENT)
        .subscribe<{
          genericNotificationEvents: SubscriptionEvent;
        }>(subscription, params.definition.variables)
        .subscribe({
          next: (value) => payload.callback(value.data?.genericNotificationEvents || null),
        }));
  }

  filter(params: GqlPayloadParams): Promise<Notification[]> {
    return buildQueryGql({
      operationName: params.operationName || 'paginateNotifications',
      operation: 'notification',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'notificationFullFragment',
      authUser: params.authUser,
    })
      .then((query) => this.use()
        .query<{ notification: Notification[] }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          notification: Notification[];
        }>) => response.data.notification));
  }

  count(params: GqlPayloadParams): Promise<number> {
    return buildQueryGql({
      operationName: params.operationName || 'GetNotificationUnreadCount',
      operation: 'notification',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'notificationCountFragment',
      authUser: params.authUser,
    })
      .then((query) => this.use()
        .query<{ notification: [{ count: number }] }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          notification: [{ count: number }];
        }>) => response.data.notification[0].count || 0));
  }

  create(params: GqlPayloadParams): Promise<Notification | undefined> {
    return buildMutationGql({
      operationName: 'TriggerNotificationGenericEvent',
      operation: 'trigger',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: '',
    })
      .then((query) => this.use(GraphQlClients.NOTIFICATION_CLIENT)
        .mutate(query, params.definition.variables))
      .then(() => new Notification());
  }

  update(params: GqlPayloadParams): Promise<Notification | undefined> {
    switch (params.operationName) {
      case 'clearAllNotificationsForUser':
        return buildMutationGql({
          operationName: 'clearAllNotificationsForUser',
          operation: 'clearAllNotificationsForUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'communityUserUidFragment',
        })
          .then((mutation) => this.use()
            .mutate<{
              clearAllNotificationsForUser: number;
            }>(mutation, params.definition.variables))
          .then((response) => response.data?.clearAllNotificationsForUser)
          .then(() => new Notification());
      case 'NotificationAddInteractedUser':
        return buildMutationGql({
          operationName: 'NotificationAddInteractedUser',
          operation: 'NotificationAddInteractedUser',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'notificationUpdateFragment',
          authUser: params.authUser,
        })
          .then((mutation) => this.use()
            .mutate<{
              NotificationAddInteractedUser: Notification;
            }>(mutation, params.definition.variables))
          .then((response) => response.data?.NotificationAddInteractedUser);

      default:
        return buildMutationGql({
          operationName: 'NotificationUpdate',
          operation: 'NotificationUpdate',
          gqlDefinition: params.definition as GqlMutationDefinition,
          fragmentName: 'notificationUpdateFragment',
          authUser: params.authUser,
        })
          .then((mutation) => this.use()
            .mutate<{ NotificationUpdate: Notification }>(mutation, params.definition.variables))
          .then((response) => response.data?.NotificationUpdate);
    }
  }

  delete(params: GqlPayloadParams): Promise<Notification | undefined> {
    return buildMutationGql({
      operationName: 'NotificationDelete',
      operation: 'NotificationDelete',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: 'notificationDeleteFragment',
    })
      .then((mutation) => this.use()
        .mutate<{ notification: Notification }>(mutation, params.definition.variables))
      .then((response) => response.data?.notification);
  }

  get(params: GqlPayloadParams): Promise<Notification | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetNotification',
      operation: 'notification',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'notificationFullFragment',
      authUser: params.authUser,
    }).then((query) => this.use().query<{ notification: Array<Notification> }, {}>(query, params.definition.variables, false)
      .then((response: ApolloQueryResult<{ notification: Array<Notification> }>) => response.data.notification[0]));
  }
}
