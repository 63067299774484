








































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import ToolboxQueryParams from '@/utils/types/ToolboxQueryParams';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import CommunityUser from '@/models/graphql/CommunityUser';
import ToolboxMessageModal from '@/components/toolbox/message/ToolboxMessageContainer.vue';
import ToolboxNotesModal from '@/components/toolbox/notes/ToolboxNotesContainer.vue';
import ToolboxDashboardContainer
  from '@/components/toolbox/dashboard/ToolboxDashboardContainer.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ToolboxAgendaModal from '@/components/toolbox/agenda/Agenda.vue';
import ToolboxTagsModal from '@/components/toolbox/tags/ToolboxTagsModal.vue';
import Exhibitor from '@/models/graphql/Exhibitor';

const agendaStore = namespace('AgendaStore');
const exhibitorStore = namespace('ExhibitorStore');

@Component({
  name: 'ToolboxModal',
  components: {
    ToolboxDashboardContainer,
    ToolboxMessageModal,
    ToolboxNotesModal,
    ToolboxTagsModal,
    ToolboxAgendaModal,
  },
})
export default class ToolboxModal extends BreakpointWrapper {
  @Getter
  private authUser!: CommunityUser;

  @Getter
  private community!: Community;

  @Prop({ required: true })
  private readonly toolboxQueryParams!: Partial<ToolboxQueryParams>;

  @Prop({ required: true })
  private readonly toolboxAction!: string;

  @agendaStore.Mutation
  private resetAgenda!: () => void;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private fetchExhibitor!: Exhibitor;

  private renderDashboard = 0;

  private ToolbarMenuActions = ToolbarMenuActions;

  private get fullPayload(): ToolboxQueryParams {
    return {
      view: this.toolboxAction,
      mode: this.toolboxQueryParams.mode || 'list',
      authUserId: this.authUser.uid || '',
      entityId: '',
      entityType: '',
      filter: '',
      searchQuery: '',
      data: this.toolboxQueryParams.data || undefined,
    };
  }

  private get chatReps(): string[] {
    if (this.fetchExhibitor) {
      // eslint-disable-next-line no-underscore-dangle
      return (this.fetchExhibitor._chatReps || []).map((rep) => rep.user?.uid || '');
    }
    return [];
  }

  private mounted(): void {
    this.logStats();
    this.$nextTick(() => {
      this.$bvModal.show('toolbox-modal');
    });
  }

  @Watch('toolboxAction')
  private logStats(): void {
    let name = '';
    switch (this.toolboxAction) {
      case ToolbarMenuActions.TOOLBAR_CHAT:
        name = 'chat';
        break;
      case ToolbarMenuActions.TOOLBAR_AGENDA:
        name = 'agenda';
        break;
      case ToolbarMenuActions.TOOLBAR_NOTES:
        name = 'notes';
        break;
      case ToolbarMenuActions.TOOLBAR_DASHBOARD:
        name = 'dashboard';
        break;
      case ToolbarMenuActions.TOOLBAR_TAGS:
        name = 'tags';
        break;
      default:
        name = '';
    }
    this.$logger.logMatomoStats(
      this.authUser,
      this.community.code || '',
      '',
      StatLoggerActions.VIEW,
      name,
      -1,
      '',
      StatLoggerCategories.TOOLBOX,
      this.$i18n.locale,
    );
  }
}
