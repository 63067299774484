var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chatErrors.haveErrors && _vm.chatErrors.findFetchConversationsError)?_c('illustration-component',{attrs:{"action-text":("" + (_vm.$t('chat.content.list-view.no-internet.action'))),"illustration-type":_vm.IllustrationType.ERROR_OCCURRED,"title":("" + (_vm.$t('chat.content.list-view.no-internet.fetch')))},on:{"on-click":_vm.retry}}):_c('div',{staticClass:"message-box-list-view d-flex flex-column h-100"},[(!_vm.isBasicLayout)?_c('div',{staticClass:"px-3 py-3 shadows-line-down"},[_c('input-search-component',{staticClass:"rounded-pill border-0",attrs:{"default-value":_vm.groupsSearchQuery,"placeholder":("" + (_vm.$t('chat.content.list-view.search.placeholder'))),"color":"gray","size":"md"},on:{"search-query":_vm.onSearch}})],1):_vm._e(),_c('div',{class:[
      'w-100 overflow-hidden',
      {'list': !_vm.isBasicLayout},
      {'h-100 px-3': _vm.isBasicLayout}
    ]},[_vm._l((_vm.resultConversations),function(item,itemIndex){return _c('message-box-list-item-component',_vm._b({key:item.uid,class:[
        {'shadows-line-up': _vm.isBasicLayout}
      ],attrs:{"chat-reps":_vm.chatReps,"data":item,"is-active":!!(_vm.selectedGroup
        && _vm.selectedGroup.uid
        && _vm.selectedGroup.uid === item.uid),"is-basic-layout":_vm.isBasicLayout,"is-selectable":false,"show-actions":!_vm.isBasicLayout,"users":item.users},on:{"open-messages":function($event){return _vm.$emit('open-messages', item)}}},'message-box-list-item-component',_vm.useTestDataAttribute(("message-box-item-" + itemIndex)),false))}),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"identifier":_vm.infiniteId,"force-use-infinite-wrapper":".message-box-list-view > .list"},on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"spinner",fn:function(){return [_c('loading-spinner-component',{staticClass:"py-4"})]},proxy:true},{key:"no-results",fn:function(){return [_c('div')]},proxy:true},{key:"no-more",fn:function(){return [_c('div')]},proxy:true}])}),(!_vm.isLoading)?[(_vm.resultConversations.length === 0 && _vm.isBasicLayout)?_c('div',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center"},[_c('p',{staticClass:"m-0 paragraph-1 font-weight-bold text-neutral-n-6-label"},[_vm._v(" "+_vm._s(_vm.$t('ep-dashboard.message.empty-state'))+" ")])]):(_vm.resultConversations.length === 0
          && _vm.groupsSearchQuery !== '')?_c('illustration-component',{attrs:{"illustration-type":_vm.IllustrationType.SEARCH_EMPTY,"message":("" + (_vm.$t('chat.content.list-view.no-results.action'))),"title":("" + (_vm.$t('chat.content.list-view.no-results.message')))}}):(_vm.resultConversations.length === 0
          && _vm.groupsSearchQuery === ''
          && _vm.nbConnections === 0)?_c('illustration-component',{attrs:{"action-text":("" + (_vm.$t('chat.content.list-view.connections.action'))),"illustration-type":_vm.IllustrationType.EMPTY_CONNECTIONS,"message":("" + (_vm.$t('chat.content.list-view.connections.message'))),"title":("" + (_vm.$t('chat.content.list-view.connections.title')))},on:{"on-click":_vm.openToConnect}}):(_vm.resultConversations.length === 0
          && _vm.groupsSearchQuery === ''
          && _vm.disableGroupCreation)?_c('illustration-component',{attrs:{"illustration-type":_vm.IllustrationType.EMPTY_MESSAGES,"title":("" + (_vm.$t('chat.content.list-view.empty.message')))}}):(_vm.resultConversations.length === 0
          && _vm.groupsSearchQuery === ''
          && !_vm.disableGroupCreation
          && _vm.nbConnections !== 0)?_c('illustration-component',{attrs:{"action-text":("" + (_vm.$t('chat.content.list-view.empty.action'))),"illustration-type":_vm.IllustrationType.EMPTY_MESSAGES,"title":("" + (_vm.$t('chat.content.list-view.empty.message')))},on:{"on-click":function($event){return _vm.changeStatus(_vm.MessageBoxActions.NEW_CONVERSATION)}}}):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }